<template>
  <v-container>
    <v-overlay :value="!loaded">
      <v-progress-circular indeterminate size="64">Buscando miembros</v-progress-circular>
    </v-overlay>
    <v-card v-if="loaded">
      <v-card-title>Listado de miembros</v-card-title>
      <v-text-field style="width: 95%; margin-left: 1%" v-model="search" label="Buscar" placeholder="Buscar filtro"
        append-icon="mdi-magnify" clearable hide-details outlined class="elevation-1"
        @click:clear="clearSearch"></v-text-field>
      <v-card-text>
        <v-data-table :headers="tableHeaders" :items="filteredMembers" :items-per-page="5" item-key="ID"
          @click:row="editMember" :sort-by="defaultSortBy" :sort-desc="defaultSortDesc">
          <!--         <template v-slot:item.redesSociales="{ item }">
          <ul>
            <li v-if="item.redesSociales.facebook">
              Facebook: {{ item.redesSociales.facebook }}
            </li>
            <li v-if="item.redesSociales.instagram">
              Instagram: {{ item.redesSociales.instagram }}
            </li>
            <li v-if="item.redesSociales.twitter">
              Twitter: {{ item.redesSociales.twitter }}
            </li>
            <li v-if="Object.keys(item.redesSociales).length > 3">
              +{{ Object.keys(item.redesSociales).length - 3 }} more...
            </li>
          </ul>
        </template> -->
          <!-- eslint-disable-next-line -->
          <template v-slot:item.direccion="{ item }">
            <ul>
              <li v-if="item.direccion.provincia">
                Provincia: {{ item.direccion.provincia }}
              </li>
              <li v-if="item.direccion.municipio">
                Municipio: {{ item.direccion.municipio }}
              </li>
              <li v-if="item.direccion.distritoMunicipal">
                Distrito Municipal: {{ item.direccion.distritoMunicipal }}
              </li>
              <li v-if="Object.keys(item.direccion).length > 3">
                +{{ Object.keys(item.direccion).length - 3 }} more...
              </li>
            </ul>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.fechaActualizacion="{ item }">
            {{ item.fechaActualizacion | formatDate }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.fechaCreacion="{ item }">
            {{ item.fechaCreacion | formatDate }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
    
  
<script>
import { getAllMembers } from "@/utils/apiRequest.js";

export default {
  data() {
    return {
      members: [],
      currentPage: 1,
      pageSize: 20,
      totalPages: 0,
      loaded: false,
      search: "",
      tableHeaders: [
        { text: "ID", value: "ID" },
        { text: "Cedula", value: "cedula" },
        { text: "Names", value: "nombres" },
        { text: "Last Names", value: "apellidos" },
        { text: "Fecha Nacimiento", value: "fechaNacimiento" },
        { text: "Sexo", value: "sexo" },
        { text: "Telefono Casa", value: "telefonoCasa" },
        { text: "Celular 1", value: "celular1" },
        { text: "Direccion", value: "direccion" },
        { text: "Fecha de creación", value: "fechaCreacion" },
        { text: "Fecha de modificación", value: "fechaActualizacion" },
      ],
      defaultSortBy: "fechaActualizacion",
      defaultSortDesc: true,
    };
  },
  async mounted() {
    this.fetchAllMembers();
  },
  computed: {
    filteredMembers() {
      const query = this.search.toLowerCase();
      return this.members.filter((member) => {
        // Convert direccion object to a string for searching
        const direccionString = JSON.stringify(member.direccion).toLowerCase();

        // Customize the filtering logic based on your needs
        return (
          member.cedula.toLowerCase().includes(query) ||
          member.nombres.toLowerCase().includes(query) ||
          member.apellidos.toLowerCase().includes(query) ||
          member.fechaNacimiento.toLowerCase().includes(query) ||
          member.sexo.toLowerCase().includes(query) ||
          member.telefonoCasa.toLowerCase().includes(query) ||
          member.celular1.toLowerCase().includes(query) ||
          direccionString.includes(query) || // Search in the direccion field
          member.fechaCreacion.toLowerCase().includes(query) ||
          member.fechaActualizacion.toLowerCase().includes(query)
        );
      });
    },
  },
  methods: {
    clearSearch() {
      // Update the data property when the clearable button is clicked
      this.search = "";
    },
    async fetchAllMembers() {
      let currentPage = 1;
      let pending = true;
      while (pending) {
        const response = await getAllMembers(currentPage, this.pageSize);
        if (response) {
          const pageMembers = response.data.members;
          if (pageMembers.length === 0) {
            pending = false;
            break; // No more pages, exit the loop
          }
          this.members.push(...pageMembers);
          currentPage++;
        } else {
          pending = false; // Error occurred, exit the loop
        }
      }
      this.loaded = true;
    },
    editMember(item) {
      // Open a new tab with the member's details for editing
      const memberId = item.ID;
      const path = `/members/edit/${memberId}`;
      window.open(path, "_blank");
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        const date = new Date(value);

        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        const hours = date.getHours() % 12 || 12;
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        const ampm = date.getHours() >= 12 ? "PM" : "AM";

        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;

        return formattedDate;
      }
      return "";
    },
  },
};
</script>
  