<template>
  <nav app id="nav">
    <v-app-bar color="primary">
      <v-btn depressed @click="goTo(''), (selectedItem = null)" style="color: white" class="d-none d-sm-block"
        color="primary">
        inicio
      </v-btn>

      <v-row class="d-flex align-center">
        <v-btn plain color="primary" @click="goTo(''), (selectedItem = null)">
          <v-img :src="require('@/assets/logo.jpg')" style="width: 3em; height: auto" alt="Work in Progress"></v-img>
        </v-btn>
        <a style="width: 85%; flex: 1" @click="goTo('')">
          <h1 style="color: white; word-break: break-word; margin-left: 1rem">
            {{ displayText }} <v-spacer></v-spacer> {{ displayComplementaryText }}
          </h1>
        </a>
      </v-row>

      <v-spacer></v-spacer>
      <v-btn plain style="color: white; word-break: break-word;" @click="goTo('structure')">
        {{"Agregar estructura"}}
      </v-btn>
      <v-menu left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="white" v-show="logged" @click="drawer = !drawer">mdi-menu</v-icon>
          <v-btn rounded v-bind="attrs" v-on="on">
            {{ username }}
            <v-icon>mdi-account</v-icon>
          </v-btn>
          <div v-show="logged">
          </div>
        </template>
        <v-list style="min-height: 100%">
          <v-list-item v-for="(item, i) in logItems" :key="i" @click="goTo(item.title)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-if="logged" v-model="drawer" app right style="min-height: 100%" color="primary">
      <v-list style="margin-top: 1.5rem">
        <v-list-item-group v-model="selectedItem" dark color="primary">
          <v-list-item v-for="(item, i) in items" :key="i" @click="goTo(item.value)">
            <v-icon dark>{{ item.icon }}</v-icon>
            <v-list-item-content style="margin-left: 5%; color: white">{{
        item.title
      }}</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import {
  getCookie,
  setCookie,
  setWithExpiry,
  getWithExpiry,
} from "@/utils/apiRequest.js";


export default {
  data: () => ({
    drawer: false,
    group: null,
    selectedItem: null,
    userRole: "",
    items: [
      {
        title: "Ver miembros",
        value: "members",
        icon: "mdi-text-box-search",
      },
      {
        title: "Validar/Registrar miembros",
        value: "registerMembers",
        icon: "mdi-file-document-edit",
      },
      {
        title: "Administración geográfica",
        value: "geoManagement",
        icon: "mdi-file-document-edit",
      },
    ],
    logItems: [{ title: "login" }],
    username: null,
    logged: false,
    counts: null,
  }),
  methods: {
    goTo(where) {
      if (where == "logout") {
        setCookie("jwt", "", 100); //delete token after 100 miliseconds
        setWithExpiry("user", "", 100);
        localStorage.removeItem("user");
        localStorage.removeItem("jwt");
        this.$router.push("/#out");
        location.reload();
      } else {
        try {
          const path = `/${where}`;
          if (this.$route.path !== path) this.$router.push(path);
        } catch (error) {
          console.log(error);
        }
      }
    },
    notificationsPending() {
      if (this.counts > 0) {
        return true;
      }
      return false;
    },
    isRole(rolename) {
      if (rolename == this.userRole) {
        return true;
      }

      return false;
    },
  },
  computed: {
    displayText() {
      return this.$vuetify.breakpoint.mdAndDown
        ? "FPSGE"
        : "FUERZA DEL PUEBLO";
    },
    displayComplementaryText() {
      return this.$vuetify.breakpoint.mdAndDown
        ? ""
        : "Sistema Geoestratégico y Estadístico";
    },
  },

  async mounted() {
    var cookie = getCookie("jwt");
    if (!cookie) {
      this.logItems = [{ title: "login" }];
    } else {
      let user = JSON.parse(getWithExpiry("user"));
      if (user != null) {
        this.username = user.user;
        this.logItems = [{ title: "logout" }];
        this.logged = true;
      }
    }
  },
};
</script>