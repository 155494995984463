<template>
  <v-container class="background">
    <v-alert type="success" :value="success">Miembro actualizado con éxito
    </v-alert>
    <v-alert type="error" :value="failed">Error actualizando miembro: {{ error }}
    </v-alert>
    <v-form ref="form" @submit.prevent="sendData">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field style="font-weight: bold" v-model="cedula" label="NO. CEDULA"
            @keyup="formatNumber('cedula', 11)"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field :rules="requiredRules('nombres')" style="font-weight: bold" v-model="nombres" label="NOMBRES"
            required></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field :rules="requiredRules('apellidos')" style="font-weight: bold" v-model="apellidos"
            label="APELLIDOS" required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field :rules="requiredRules('fecha de nacimiento')" style="font-weight: bold" v-model="fechaNacimiento"
            label="FECHA DE NACIMIENTO" type="date" required></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select :rules="requiredRules('sexo')" style="font-weight: bold" v-model="sexo" label="SEXO" :items="sexos"
            required></v-select>
        </v-col>
        <v-col cols="12" sm="6" style="padding-top: 2em">
          <v-autocomplete v-model="direccion.provincia" label="Provincia de residencia" :items="provincias"
            required></v-autocomplete>

          <v-autocomplete v-model="direccion.municipio" label="Municipio" :items="municipios" required></v-autocomplete>

          <v-autocomplete v-model="direccion.distritoMunicipal" label="Distrito Municipal" :items="distritosMunicipales"
            required></v-autocomplete>

          <v-autocomplete v-model="direccion.seccion" label="Sección" :items="secciones" required></v-autocomplete>

          <v-autocomplete v-model="direccion.barrio" label="Barrio o Paraje" :items="barrios" required></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" style="padding-top: 2em">
          <v-text-field :rules="requiredRules('calle')" style="font-weight: bold" v-model="direccion.calle" label="Calle"
            required></v-text-field>
          <v-text-field :rules="requiredRules('número de vivienda')" style="font-weight: bold" v-model="direccion.numero"
            label="Número" required></v-text-field>
          <v-text-field style="font-weight: bold" v-model="direccion.apto" label="Número de apartamento"></v-text-field>
        </v-col>
      </v-row>
      <!--       <v-row>
        <v-col cols="12" sm="6">
          <v-select
            style="font-weight: bold"
            v-model="cargo"
            label="CARGO QUE OSTENTA"
            :items="cargos"
            required
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            style="font-weight: bold"
            v-model="demarcacion"
            label="DEMARCACIÓN ASIGNADA"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            style="font-weight: bold"
            v-model="circunscripción"
            label="circunscripción"
            required
          ></v-text-field>
        </v-col>
      </v-row> -->
      <!--       <v-row>
        <v-col cols="12" sm="6">
          <v-file-input
            style="font-weight: bold"
            v-model="cedulaFotos.front"
            label="CARGAR COPIA DE CÉDULA (Frente)"
            accept="image/*"
            required
          ></v-file-input>
        </v-col>
        <v-col cols="12" sm="6">
          <v-file-input
            style="font-weight: bold"
            v-model="cedulaFotos.back"
            label="CARGAR COPIA DE CÉDULA (Dorso)"
            accept="image/*"
            required
          ></v-file-input>
        </v-col>
      </v-row> -->
      <v-row>
        <!--   <v-col cols="12" sm="6">
          <v-text-field
            style="font-weight: bold"
            v-model="redesSociales.facebook"
            label="Facebook"
          ></v-text-field>
          <v-text-field
            style="font-weight: bold"
            v-model="redesSociales.instagram"
            label="Instagram"
          ></v-text-field>
          <v-text-field
            style="font-weight: bold"
            v-model="redesSociales.twitter"
            label="Twitter"
          ></v-text-field>
          <v-text-field
            style="font-weight: bold"
            v-model="redesSociales.tiktok"
            label="TikTok"
          ></v-text-field>
          <v-text-field
            style="font-weight: bold"
            v-model="redesSociales.youtube"
            label="YouTube"
          ></v-text-field>
        </v-col> -->
        <v-col cols="12" sm="6">
          <v-text-field style="font-weight: bold" v-model="telefonoCasa" label="Teléfono casa"></v-text-field>
          <v-text-field :rules="[validateCelular1]" style="font-weight: bold" v-model="celular1" label="Celular 1"
            @keyup="formatNumber('celular1', 10)"></v-text-field>
          <v-text-field style="font-weight: bold" v-model="celular2" label="Celular 2"
            @keyup="formatNumber('celular2', 10)"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn type="submit" color="primary">Registrar</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64">Buscando cédula</v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<style scoped>
.background {
  /* background-image: url("../assets/logoFPwTransparent.jpg"); */
  background-position: center;
  background-size: cover;
  /*  background-color: rgba(0, 0, 0, 0.1); */
}
</style>
<script>
import {
  addMember,
  fetcPersonInJCE,
  /* addPersonInJCE ,*/
  fetcPersonInJCEwoCed,
  GetGeography,
} from "@/utils/apiRequest.js";

export default {
  data() {
    return {
      cedula: "",
      nombres: "",
      apellidos: "",
      found: false,
      fechaNacimiento: "",
      sexo: "",
      direccion: {
        provincia: "",
        municipio: "",
        distritoMunicipal: "",
        seccion: "",
        barrio: "",
        circunscripcion: "",
        calle: "",
        numero: "",
        apto: "",
      },
      location: "",
      failed: false,
      success: false,
      cargo: "",
      demarcacion: "",
      error: "",
      cedulaFotos: {
        front: null,
        back: null,
      },
      redesSociales: {
        facebook: "",
        instagram: "",
        twitter: "",
        tiktok: "",
        youtube: "",
      },
      telefonoCasa: "",
      celular1: "",
      celular2: "",
      sexos: ["Hombre", "Mujer", "Prefiero no decir"],
      cargos: [
        "VICESECRETARIO NACIONAL",
        "VICESECRETARIO REGIONAL",
        "VICESECRETARIO PROVINCIAL",
        "DIRECTOR PROVINCIAL",
        "ENCARGADO MUNICIPAL",
        "COORDINADOR",
        "ENLACE",
      ],
      cargosEstructura: [
        "PRESIDENTE PROVINCIAL",
        "PRESIDENTE DE CIRCUNSCRIPCIÓN",
        "ENCARGADO MUNICIPAL",
        "ENCARGADO DE DISTRITO",
        "COORDINADOR DE SECCIONES",
        "ENLACE DE BARRIO/PARAJE",
      ],
      dataMap: new Map([
        ["AZUA", 3],
        ["BAHORUCO", 4],
        ["BARAHONA", 5],
        ["DAJABON", 6],
        ["DISTRITO NACIONAL", 1],
        ["DUARTE", 7],
        ["EL SEYBO", 8],
        ["ELIAS PIÑA", 9],
        ["ESPAILLAT", 10],
        ["HATO MAYOR", 11],
        ["HERMANAS MIRABAL", 22],
        ["INDEPENDENCIA", 12],
        ["LA ALTAGRACIA", 2],
        ["LA ROMANA", 13],
        ["LA VEGA", 14],
        ["MARIA TRINIDAD SANCHEZ", 15],
        ["MONSEÑOR NOUEL", 16],
        ["MONTE PLATA", 18],
        ["MONTECRISTI", 17],
        ["PEDERNALES", 19],
        ["PERAVIA", 20],
        ["PUERTO PLATA", 21],
        ["SAMANA", 23],
        ["SAN CRISTOBAL", 24],
        ["SAN JOSE DE OCOA", 31],
        ["SAN JUAN", 25],
        ["SAN PEDRO DE MACORIS", 26],
        ["SANCHEZ RAMIREZ", 27],
        ["SANTIAGO", 28],
        ["SANTIAGO RODRIGUEZ", 29],
        ["SANTO DOMINGO", 32],
        ["VALVERDE", 30],
      ]),
      geography: {},
      loading: false,
    };
  },
  async mounted() {
    const userAgent = navigator.userAgent;
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
    // Improved Safari detection without using 'safari' variable
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

    if (isIOS && isSafari) {
      // For Safari on iOS devices, try to get location which will prompt for permission
      await this.locateGeoLocationSafari();
    } else if (!isIOS) {
      // For non-iOS devices, call the original location function
      await this.locateGeoLocation();
    }
    console.log("location: ", this.location);

    let resp = await GetGeography();
    if (resp.data) {
      if (resp.data.output) {
        this.geography = resp.data.output;
      }
    }
  },
  watch: {
    cedula(newCedula) {
      if (newCedula.length >= 11) {
        this.getCedInfo();
      }
    },
    fechaNacimiento(fech) {
      // Regular expression to validate the date format (YYYY-MM-DD)
      const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

      if (dateFormatRegex.test(fech)) {
        // Extract the year from the date
        const year = parseInt(fech.split("-")[0], 10);

        // Check if the year is greater than 1920
        if (year > 1920) {
          this.getCedInfoWithCed();
        }
      }
    },
  },
  methods: {
    formatNumber(field, maxLength) {
      // Get the input value
      let inputField = this[field];
      // Remove all characters that are not numbers
      inputField = inputField.replace(/[^0-9]/g, "");
      // Update the editedMember property by creating a new object
      if (inputField.length > maxLength) {
        inputField = inputField.substring(0, maxLength); // Truncate to max length
      }
      this[field] = inputField;
    },
    requiredRules(field) {
      return [(v) => !!v || `${field} es obligatorio`];
    },

    async getCedInfo() {
      let sexoMap = new Map();
      sexoMap.set("M", "Hombre");
      sexoMap.set("F", "Mujer");
      this.loading = true; // Display loading indicator

      // Perform backend request
      const response = await fetcPersonInJCE(this.cedula);

      // Handle the response accordingly
      if (response && response.data.status !== "failed") {
        let personFound = response.data.output;

        this.nombres = personFound.nombres;
        this.apellidos = personFound.apellidos;
        this.fechaNacimiento = personFound.fechaNacimiento.slice(0, 10);
        this.sexo = sexoMap.get(personFound.sexo);
        this.found = true;
      }

      this.loading = false; // Hide loading indicator
    },
    async getCedInfoWithCed() {
      let sexoMap = new Map();
      sexoMap.set("M", "Hombre");
      sexoMap.set("F", "Mujer");
      this.loading = true; // Display loading indicator
      let person = {
        nombres: this.nombres,
        apellidos: this.apellidos,
        fechaNacimiento: this.fechaNacimiento,
      };
      // Perform backend request
      const response = await fetcPersonInJCEwoCed(person);

      // Handle the response accordingly
      if (response && response.data.status !== "failed") {
        let personFound = response.data.output;

        this.nombres = personFound.nombres;
        this.apellidos = personFound.apellidos;
        this.fechaNacimiento = personFound.fechaNacimiento.slice(0, 10);
        this.sexo = sexoMap.get(personFound.sexo);
        this.cedula = personFound.cedula;
        this.found = true;
      }

      this.loading = false; // Hide loading indicator
    },
    locateGeoLocation: async function () {
      // Request geolocation permission
      console.log("normal location");
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "granted") {
            // Permission already granted, proceed with getting location
            this.getCurrentLocation();
          } else if (permissionStatus.state === "prompt") {
            // Permission not yet granted, ask the user for permission
            navigator.geolocation.getCurrentPosition(
              (position) => {
                this.getCurrentLocation(position);
              },
              (error) => {
                console.log(error);
              },
              { enableHighAccuracy: true } // Request high accuracy location
            );
          } else {
            // Permission denied
            console.log("Geolocation permission denied.");
          }
        })
        .catch((error) => {
          console.log("Error checking geolocation permission:", error);
        });
    },

    getCurrentLocation: function async(position) {
      const success = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        this.location = latitude + "," + longitude;

        console.log("finish location");
      };

      const error = (err) => {
        console.log(err);
      };

      if (position) {
        // Use the provided position if available
        success(position);
      } else {
        navigator.geolocation.getCurrentPosition(success, error, {
          enableHighAccuracy: true, // Request high accuracy location
        });
      }
    },

    // Function to request permission for iOS Geolocation
    locateGeoLocationSafari: async function () {
      // Geolocation options
      console.log("Location in zafari");
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      const success = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log("Zafari locations", latitude, " ", longitude);
        this.location = `${latitude},${longitude}`;
        console.log("finish location");
        // Handle the captured location here
      };

      const error = (err) => {
        console.log(err)
        console.warn(`ERROR(${err.code}): ${err.message}`);
        if (err.code === 2) {
          // POSITION_UNAVAILABLE
          // Prompt user to enable location services
          alert(
            "To use this feature, please enable Location Services for Safari in your device's Settings."
          );
        } else if (err.code === 1) {
          // PERMISSION_DENIED
          // Prompt user to give permission
          alert(
            "Location access was denied. Please allow permission for Location Services in your browser settings."
          );
        } else {
          // Handle other errors
          alert(
            "There was an error retrieving your location. Please try again."
          );
        }
      };

      // Request geolocation, which will trigger the permission prompt in Safari on iOS
      navigator.geolocation.getCurrentPosition(success, error, options);
    },

    resetData() {
      this.error = "";
      this.success = false;
      this.failed = false;
      this.cedula = "";
      this.nombres = "";
      this.apellidos = "";
      this.fechaNacimiento = "";
      this.sexo = "";
      this.found = false;
      this.location = "";
      this.direccion = {
        provincia: "",
        municipio: "",
        distritoMunicipal: "",
        seccion: "",
        barrio: "",
        calle: "",
        numero: "",
      };
      this.cargo = "";
      this.demarcacion = "";
      this.cedulaFotos = {
        front: null,
        back: null,
      };
      this.redesSociales = {
        facebook: "",
        instagram: "",
        twitter: "",
        tiktok: "",
        youtube: "",
      };
      this.telefonoCasa = "";
      this.celular1 = "";
      this.celular2 = "";
    },
    async sendData() {
      if (!this.$refs.form.validate()) {
        return;
      }
      var member = {
        cedula: this.cedula,
        nombres: this.nombres,
        apellidos: this.apellidos,
        fechaNacimiento: this.fechaNacimiento,
        sexo: this.sexo,
        direccion: {
          provincia: this.direccion.provincia,
          municipio: this.direccion.municipio,
          distritoMunicipal: this.direccion.distritoMunicipal,
          seccion: this.direccion.seccion,
          barrio: this.direccion.barrio,
          calle: this.direccion.calle,
          circunscripcion: this.direccion.circunscripcion,
          numero: this.direccion.numero,
          apto: this.direccion.apto,
        },
        location: this.location,
        cargo: this.cargo,
        demarcacion: this.demarcacion,
        cedulaFotos: {
          front: this.cedulaFotos.front,
          back: this.cedulaFotos.back,
        },
        redesSociales: this.redesSociales,
        telefonoCasa: this.telefonoCasa,
        celular1: this.celular1,
        celular2: this.celular2,
      };
      /* if (!this.found) {
        let memberCopy = member;
        memberCopy.Provincia = this.dataMap.get(
          member.direccion.provincia.toUpperCase()
        );
        const response = addPersonInJCE(memberCopy);
        if (response) {
          if (response.data.status != "success") {
            console.log(response);
          }
        }
      } */
      const response = await addMember(member);
      if (response) {
        if (response.data.status != "failed") {
          this.$refs.form.reset();
          this.resetData();
          this.success = true;
          this.error = "";
          this.failed = false;
          this.scrollToTop();
          return;
        }
        this.failed = true;
        this.error = response.data.error;
        this.success = false;
        this.scrollToTop();
      }
    },
    scrollToTop() {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Add smooth scrolling for a smoother effect
      });
    },

    validateCelular1(v) {
      // Use a regular expression to check if the first 3 digits are 829, 809, or 849
      const pattern = /^(829|809|849)/;
      if (!pattern.test(v)) {
        return "Número de teléfono invaálido, debe inciar con 829, 809, or 849.";
      }
      return true;
    },
  },
  computed: {
    provincias() {
      if (this.geography && this.geography.length > 0) {
        const allProvincias = this.geography.flatMap((region) =>
          Object.values(region.provincia).map((provincia) => ({
            text: provincia.nombre,
            value: provincia.nombre,
          }))
        );

        return allProvincias;
      } else {
        return [];
      }
    },
    municipios() {
      if (
        this.direccion.provincia &&
        this.geography &&
        this.geography.length > 0
      ) {
        const provinciaCodigo = this.direccion.provincia;
        const allMunicipios = this.geography.flatMap((region) =>
          Object.values(region.provincia).flatMap((provincia) => {
            if (provincia.nombre === provinciaCodigo) {
              return Object.values(provincia.municipio).map((municipio) => ({
                text: municipio.nombre,
                value: municipio.nombre,
              }));
            }
            return [];
          })
        );

        return allMunicipios;
      } else {
        return [];
      }
    },
    distritosMunicipales() {
      if (
        this.direccion.municipio &&
        this.direccion.provincia &&
        this.geography &&
        this.geography.length > 0
      ) {
        const provinciaCodigo = this.direccion.provincia;
        const municipioCodigo = this.direccion.municipio;
        const allDistritosMunicipales = this.geography.flatMap((region) =>
          Object.values(region.provincia).flatMap((provincia) => {
            if (provincia.nombre === provinciaCodigo) {
              return Object.values(provincia.municipio).flatMap((municipio) => {
                if (municipio.nombre === municipioCodigo) {
                  return Object.values(municipio.distMunicipal).map(
                    (distritoMunicipal) => ({
                      text: distritoMunicipal.nombre,
                      value: distritoMunicipal.nombre,
                    })
                  );
                }
                return [];
              });
            }
            return [];
          })
        );

        return allDistritosMunicipales;
      } else {
        return [];
      }
    },
    secciones() {
      if (
        this.direccion.distritoMunicipal &&
        this.direccion.municipio &&
        this.direccion.provincia &&
        this.geography &&
        this.geography.length > 0
      ) {
        const provinciaCodigo = this.direccion.provincia;
        const municipioCodigo = this.direccion.municipio;
        const distritoMunicipalCodigo = this.direccion.distritoMunicipal;
        const allSecciones = this.geography.flatMap((region) =>
          Object.values(region.provincia).flatMap((provincia) => {
            if (provincia.nombre === provinciaCodigo) {
              return Object.values(provincia.municipio).flatMap((municipio) => {
                if (municipio.nombre === municipioCodigo) {
                  return Object.values(municipio.distMunicipal).flatMap(
                    (distritoMunicipal) => {
                      if (
                        distritoMunicipal.nombre === distritoMunicipalCodigo
                      ) {
                        return Object.values(distritoMunicipal.sección).map(
                          (seccion) => ({
                            text: seccion.nombre,
                            value: seccion.nombre,
                          })
                        );
                      }
                      return [];
                    }
                  );
                }
                return [];
              });
            }
            return [];
          })
        );

        return allSecciones;
      } else {
        return [];
      }
    },
    barrios() {
      if (
        this.direccion.seccion &&
        this.direccion.distritoMunicipal &&
        this.direccion.municipio &&
        this.direccion.provincia &&
        this.geography &&
        this.geography.length > 0
      ) {
        const provinciaCodigo = this.direccion.provincia;
        const municipioCodigo = this.direccion.municipio;
        const distritoMunicipalCodigo = this.direccion.distritoMunicipal;
        const seccionCodigo = this.direccion.seccion;
        const allBarrios = this.geography.flatMap((region) =>
          Object.values(region.provincia).flatMap((provincia) => {
            if (provincia.nombre === provinciaCodigo) {
              return Object.values(provincia.municipio).flatMap((municipio) => {
                if (municipio.nombre === municipioCodigo) {
                  return Object.values(municipio.distMunicipal).flatMap(
                    (distritoMunicipal) => {
                      if (
                        distritoMunicipal.nombre === distritoMunicipalCodigo
                      ) {
                        return Object.values(distritoMunicipal.sección).flatMap(
                          (seccion) => {
                            if (seccion.nombre === seccionCodigo) {
                              // Combine 'barrios' and 'SubBarrios' into 'barrios'
                              const combinedBarrios = {
                                ...seccion.barrios,
                                ...seccion.SubBarrios,
                              };
                              return Object.values(combinedBarrios).map(
                                (barrio) => ({
                                  text: barrio.nombre,
                                  value: barrio.nombre,
                                })
                              );
                            }
                            return [];
                          }
                        );
                      }
                      return [];
                    }
                  );
                }
                return [];
              });
            }
            return [];
          })
        );

        return allBarrios;
      } else {
        return [];
      }
    },
  },
};
</script>
