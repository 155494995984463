<template>
  <v-container>
    <v-layout>
      <v-flex style="padding-top: 5%">
        <v-card elevation="20">
          <v-card-text>
            <v-form @submit.prevent="login">
              <v-layout>
                <v-flex>
                  <v-text-field name="usrn" label="Nombre de usuario" v-model="usrn" id="usrn" required />
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex>
                  <v-text-field name="pwd" label="Contraseña" v-model="pwd" id="pwd" required
                    type="password"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex>
                  <v-btn color="tertiary" style="color: white" type="submit">Login</v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-alert type="error" :value="unvalid">Usuario o contraseña incorrecta</v-alert>
        </v-card>
        <v-col style="margin-left: 45%; margin-top: 2%">
          <v-progress-circular indeterminate class="loading" :size="120" color="tertiary" v-if="loading">
            Iniciando sesión
          </v-progress-circular>
        </v-col>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { setCookie, userLogin, setWithExpiry } from "@/utils/apiRequest.js";
export default {
  data() {
    return {
      usrn: "",
      pwd: "",
      unvalid: false,
      reload: false,
      backendURL: "",
      loading: false,
    };
  },
  mounted() {
    // eslint-disable-next-line
    this.backendURL = process.env.VUE_APP_LOCAL_BACKEND;
    // eslint-disable-next-line
    if (process.env.APP_ENV === "prod") {
      // eslint-disable-next-line
      this.backendURL = process.env.BACKEND;
    }
  },
  methods: {
    async login() {
      this.loading = true;
      let response = await userLogin(this);
      if (response) {
        if (response.data.status === "success") {
          this.unvalid = false;
          this.loading = false;
          setCookie("jwt", response.data.output.token, 24);
          setWithExpiry("jwt", response.data.output.token, 24);
          setWithExpiry("user", JSON.stringify(response.data.output.User), 24);

          this.$router.push("/#logged");
          location.reload(); // Refresh the site
        } else {
          this.unvalid = true;
          this.loading = false;
        }
      } else {
        this.unvalid = true;
        this.loading = false;
      }
    },
  },
};
</script>