<template>
  <v-card v-if="loaded">
    <v-card-title>Listado de consejos de vecinos</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="tableHeaders"
        :items="councils"
        :items-per-page="5"
        item-key="name"
        @click:row="editCouncil"
      ><!-- eslint-disable-next-line -->
        <template v-slot:item.presidente="{ item }">
          {{ item.presidente.nombres }} {{ item.presidente.apellidos }}
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.vicePresidente="{ item }">
          {{ item.vicePresidente.nombres }} {{ item.vicePresidente.apellidos }}
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.secretario="{ item }">
          {{ item.secretario.nombres }} {{ item.secretario.apellidos }}
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.tesorero="{ item }">
          {{ item.tesorero.nombres }} {{ item.tesorero.apellidos }}
        </template>
       
      </v-data-table>
    </v-card-text>
    <v-card-actions class="text-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        :total-visible="10"
        @input="fetchCouncils"
      ></v-pagination>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getAllNeighborhoodCouncils } from "@/utils/apiRequest.js";

export default {
  data() {
    return {
      councils: [],
      currentPage: 1,
      pageSize: 20,
      totalPages: 0,
      loaded: false,
      tableHeaders: [
        { text: "Nombre", value: "nombre" },
        { text: "Presidente", value: "presidente" },
        { text: "Vicepresidente", value: "vicePresidente" },
        { text: "Secretario", value: "secretario" },
        { text: "Tesorero", value: "tesorero" },
      ],
    };
  },
  async mounted() {
    this.fetchCouncils();
  },
  methods: {
    async fetchCouncils() {
      const response = await getAllNeighborhoodCouncils(
        this.currentPage,
        this.pageSize
      );
      if (response) {
        this.councils = response.data.councilMembers;
        this.totalPages = response.data.totalPages;
        this.loaded = true;
      }
    },
    editCouncil(item) {
      // Open a new tab with the council's details for editing
      const councilName = item.id;
      const path = `/council/edit/${councilName}`;
      window.open(path, "_blank");
    },
  },
};
</script>
