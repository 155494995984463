import axios from "axios";
import Cookies from "js-cookie";

// eslint-disable-next-line
var HOST = process.env.VUE_APP_LOCAL_BACKEND;
// eslint-disable-next-line
if (process.env.APP_ENV === "prod") {
  // eslint-disable-next-line
  HOST = process.env.BACKEND;
}

export function getCookie(cname) {
  return Cookies.get(cname);
}

// SetCookies saves the session token
// cname: token name
// cvalue: token value
// exdays: the number of days until the token expires
export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  var multyplayer = 1 * 60 * 60 * 1000; // to hour
  d.setTime(d.getTime() + exdays * multyplayer); // from miliseconds to days;
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function setWithExpiry(key, value, ttl) {
  const now = new Date();
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl * 60 * 60 * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

// User

export const registerUser = async (user) => {
  try {
    const response = await axios.post(`${HOST}/v1/users`, {
      username: user.username,
      password: user.password,
      UserRoles: user.roles,
      email: user.email,
      enabled: user.enabled,
      organizationName: user.organizationName,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const editUser = async (id, user) => {
  try {
    const response = await axios.put(`${HOST}/v1/users/${id}`, {
      id: user.id,
      username: user.username,
      password: user.password,
      UserRoles: user.roles,
      email: user.email,
      enabled: user.enabled,
      organizationName: user.organizationName,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const userLogin = async (User) => {
  try {
    const response = await axios.post(`${HOST}/v1/users/login`, {
      username: User.usrn,
      password: User.pwd,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await axios.get(`${HOST}/v1/users/getMe`);
    return response;
  } catch (error) {
    if (!error.response) {
      return "Server down";
    }
    return error.response;
  }
};

export const GetAllUsers = async () => {
  try {
    const response = await axios.get(`${HOST}/v1/users`);
    return response;
  } catch (error) {
    if (!error.response) {
      return "Server down";
    }
    return error.response;
  }
};

export const GetUser = async (userID) => {
  try {
    const response = await axios.get(`${HOST}/v1/users/${userID}`);
    return response;
  } catch (error) {
    if (!error.response) {
      return "Server down";
    }
    return error.response;
  }
};

export const addUser = async (member) => {
  try {
    const response = await axios.post(`${HOST}/v1/users`, member);
    return response;
  } catch (error) {
    if (!error.response) {
      return "Server down";
    }
    return error.response;
  }
};

export const deleteUser = async (userID) => {
  try {
    const response = await axios.delete(`${HOST}/v1/users/${userID}`);
    return response;
  } catch (error) {
    if (!error.response) {
      return "Server down";
    }
    return error.response;
  }
};



export const GetLocationsInside = async (loc) => {
  try {
    const response = await axios.post(`${HOST}/v1/geospatial/location/inside`, {
      name: loc.name,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const GetLocationsInsideBarrio = async (loc) => {
  try {
    const response = await axios.post(`${HOST}/v1/geospatial/location/inside/barrio`, {
      name: loc.name,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const GetGeography = async () => {
  try {
    const response = await axios.get(`${HOST}/v1/geospatial/geography`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const addMember = async (member) => {
  try {
    if (member.cedulaFotos.front) {
      var encodedFront = await encodePhoto(member.cedulaFotos.front);
    }
    if (member.cedulaFotos.back) {
      var encodedBack = await encodePhoto(member.cedulaFotos.back);
    }

    const payload = {
      ...member,
      cedulaFotos: {
        front: encodedFront,
        back: encodedBack,
      },
    };
    const response = await axios.post(`${HOST}/v1/members`, payload);
    return response;
  } catch (error) {
    if (!error.response) {
      return "Server down";
    }
    return error.response;
  }
};

const encodePhoto = (photo) => {
  return new Promise((resolve, reject) => {
    if (photo == "") {
      return null;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const encodedData = reader.result.replace(/^data:(.*;base64,)?/, '');
      if (encodedData) {
        resolve(encodedData);
      } else {
        reject(new Error('Failed to encode photo'));
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(photo);
  });
};


export const fetchMember = async (memberId) => {
  try {
    const response = await axios.get(`${HOST}/v1/members/${memberId}`);
    return response
  } catch (error) {
    console.error(error);
  }
}

export const deleteMember = async (memberId) => {
  try {
    const response = await axios.delete(`${HOST}/v1/members/${memberId}`);
    return response
  } catch (error) {
    console.error(error);
  }
}

export const getAllMembers = async (page, size) => {
  try {
    const response = await axios.get(`${HOST}/v1/members?page=${page}&pageSize=${size}`);
    return response
  } catch (error) {
    if (!error.response) {
      return "Server down";
    }
    return error.response;
  }
}

export const updateMember = async (updatedMember) => {
  try {
    if (updatedMember.cedulaFotos.front) {
      var encodedFront = await encodePhoto(updatedMember.cedulaFotos.front);
    }
    if (updatedMember.cedulaFotos.back) {
      var encodedBack = await encodePhoto(updatedMember.cedulaFotos.back);
    }

    const payload = {
      ...updatedMember,
      cedulaFotos: {
        front: encodedFront,
        back: encodedBack,
      },
    };
    const response = await axios.put(`${HOST}/v1/members/${payload.ID}`, payload);
    return response;
  } catch (error) {
    if (!error.response) {
      return "Server down";
    }
    return error.response;
  }
}


export const createNeighborhoodCouncil = async (council) => {
  try {
    const response = await axios.post(`${HOST}/v1/neighborhood-councils`, council);
    return response.data;
  } catch (error) {
    if (!error.response) {
      return "Server down";
    }
    return error.response.data;
  }
};


export const getAllNeighborhoodCouncils = async (page, size) => {
  try {
    const response = await axios.get(`${HOST}/v1/neighborhood-councils?page=${page}&pageSize=${size}`);
    return response
  } catch (error) {
    if (!error.response) {
      return "Server down";
    }
    return error.response;
  }
}


export const updateNeighborhoodCouncils = async (updatedNeighborhoodCouncil) => {
  try {
    const { ID, ...dataToSend } = updatedNeighborhoodCouncil;
    const response = await axios.put(`${HOST}/v1/neighborhood-councils/${ID}`, dataToSend);
    return response;
  } catch (error) {
    if (!error.response) {
      return "Server down";
    }
    return error.response;
  }
}

export const fetcNeighborhoodCouncil = async (NeighborhoodCouncilsId) => {
  try {
    const response = await axios.get(`${HOST}/v1/neighborhood-councils/${NeighborhoodCouncilsId}`);
    return response
  } catch (error) {
    console.error(error);
  }
}


export const fetcPersonInJCE = async (ced) => {
  try {
    const response = await axios.get(`${HOST}/v1/person/${ced}`);
    return response
  } catch (error) {
    console.error(error);
  }
}

export const addPersonInJCE = async (person) => {
  try {
    const response = await axios.post(`${HOST}/v1/person/`, person);
    return response
  } catch (error) {
    console.error(error);
  }
}

export const fetcPersonInJCEwoCed = async (person) => {
  try {
    const response = await axios.post(`${HOST}/v1/person/info`, person);
    return response
  } catch (error) {
    console.error(error);
  }
}

// Define a mapping from user-friendly field names to table names
const fieldToTableMap = {
  province: 'provinciasgeo',
  municipality: 'municipiogeo',
  distmunicipality: 'distritomungeo',
  sector: 'secciongeo',
  neighborhood: 'barriosparajegeo',
};


export const GetMembersInRange = async (smallestPopulatedField, page = 1, pageSize = 10) => {
  try {
    const { field, value } = smallestPopulatedField;
    // Use the mapping to get the corresponding table name
    const zoneTable = fieldToTableMap[field.toLowerCase()] || "";

    if (!zoneTable) {
      return "Invalid field provided";
    }

    const params = new URLSearchParams({
      page,
      pageSize,
      zoneTable,
      name: value,
    });

    const url = `${HOST}/v1/members/zone?${params.toString()}`;

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    if (!error.response) {
      return "Server down";
    }
    return error.response.data;
  }
};


export const GetMapGeo = async (smallestPopulatedField) => {
  try {
    const { field, value } = smallestPopulatedField;
    // Use the mapping to get the corresponding table name
    const zoneTable = fieldToTableMap[field.toLowerCase()] || "";

    const url = `${HOST}/v1/geospatial/geo/${zoneTable}/${value}`;

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    if (!error.response) {
      return "Server down";
    }
    return error.response.data;
  }
};


export const GetMapRecint = async (smallestPopulatedField) => {
  try {
    const { field, value } = smallestPopulatedField;
    // Use the mapping to get the corresponding table name
    //const zoneTable = fieldToTableMap[field.toLowerCase()] || '';
    console.log(field)
    const url = `${HOST}/v1/members/zone/recint/${value}`;

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    if (!error.response) {
      return "Server down";
    }
    return error.response.data;
  }
};

