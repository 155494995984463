import Router from "vue-router";
import Vue from "vue";
//import landingPage from "@/views/landing.vue";
import loginPage from "@/views/loginView.vue";
import addMembers from "@/views/addMembers.vue";
import seeMember from "@/components/fetchMemberInfo.vue";
import MemberList from "@/views/MemberList.vue";
import EditMember from "@/views/editMember.vue";
import addCouncilMember from "@/views/addCouncilMembers.vue";
import listCouncilMember from "@/components/CouncilMembersList.vue";
import editCouncilMembers from "@/components/editCouncilMembers.vue";
import registerStructure from "@/components/registerStructure.vue";
import geoManagementView from "@/views/geoManagement.vue";
import { getWithExpiry } from "@/utils/apiRequest";

Vue.use(Router);

var router = new Router({
  routes: [
    //{ path: "/", name: "Landing", component: landingPage },
    {
      path: "/", name: "Landing", component: addMembers, meta: {
        guest: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: loginPage,
      meta: {
        guest: true,
      },
    },
    {
      path: "/registerMembers",
      name: "registerMembers",
      component: addMembers,
      meta: {
        guest: true,
        is_admin: true,
        is_vice_secretario_nacional: true,
        is_vice_secretario_regional: true,
        is_vice_secretario_provincial: true,
        is_encargado_municipal: true,
        is_coordinador: true,
        is_enlace: true,
      },
    },
    {
      path: "/members/:id",
      name: "member-details",
      component: seeMember,
      meta: {
        guest: false,
        is_admin: true,
        is_vice_secretario_nacional: true,
        is_vice_secretario_regional: true,
        is_vice_secretario_provincial: true,
        is_encargado_municipal: true,
        is_coordinador: true,
        is_enlace: true,
      },
    },
    {
      path: "/members",
      name: "MemberList",
      component: MemberList,
      meta: {
        guest: false,
        is_admin: true,
        is_vice_secretario_nacional: true,
        is_vice_secretario_regional: true,
        is_vice_secretario_provincial: true,
        is_encargado_municipal: true,
        is_coordinador: true,
        is_enlace: true,
      },
    },
    {
      path: "/members/edit/:id",
      name: "EditMember",
      component: EditMember,
      meta: {
        guest: false,
        is_admin: true,
        is_vice_secretario_nacional: true,
        is_vice_secretario_regional: true,
        is_vice_secretario_provincial: true,
        is_encargado_municipal: true,
        is_coordinador: true,
        is_enlace: true,
      },
    },
    {
      path: "/council/add",
      name: "AddCouncil",
      component: addCouncilMember,
      meta: {
        guest: true,
        is_admin: true,
        is_vice_secretario_nacional: true,
        is_vice_secretario_regional: true,
        is_vice_secretario_provincial: true,
        is_encargado_municipal: true,
        is_coordinador: true,
        is_enlace: true,
      },
    },
    {
      path: "/council",
      name: "CouncilList",
      component: listCouncilMember,
      meta: {
        guest: false,
        is_admin: true,
        is_vice_secretario_nacional: true,
        is_vice_secretario_regional: true,
        is_vice_secretario_provincial: true,
        is_encargado_municipal: true,
        is_coordinador: true,
        is_enlace: true,
      },
    },
    {
      path: "/council/edit/:id",
      name: "CouncilList",
      component: editCouncilMembers,
      meta: {
        guest: false,
        is_admin: true,
        is_vice_secretario_nacional: true,
        is_vice_secretario_regional: true,
        is_vice_secretario_provincial: true,
        is_encargado_municipal: true,
        is_coordinador: true,
        is_enlace: true,
      },
    },
    {
      path: "/structure",
      name: "Estructura",
      component: registerStructure,
      meta: {
        guest: true,
        is_admin: true,
        is_vice_secretario_nacional: true,
        is_vice_secretario_regional: true,
        is_vice_secretario_provincial: true,
        is_encargado_municipal: true,
        is_coordinador: true,
        is_enlace: true,
      },
    },
    {
      path: "/geoManagement",
      name: "Administración geográfica",
      component: geoManagementView,
      meta: {
        guest: false,
        is_admin: true,
        is_vice_secretario_nacional: true,
        is_vice_secretario_regional: true,
        is_vice_secretario_provincial: true,
        is_encargado_municipal: true,
        is_coordinador: true,
        is_enlace: true,
      },
    },
  ],
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => !record.meta.guest)) {
    if (getWithExpiry("jwt") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      let user = JSON.parse(getWithExpiry("user"));
      if (user == null) {
        next({ name: "Landing" });
      } else {
        if (to.matched.some((record) => record.meta.is_admin) && user.role.toUpperCase().includes("ADMIN")) {
          next();
        } else if (to.matched.some((record) => record.meta.is_auxiliar) && user.permissions.toUpperCase().includes("AUXILIAR")) {
          next();
        } else if (to.matched.some((record) => record.meta.is_vice_secretario_nacional) && user.role.toUpperCase().includes("VICESECRETARIO NACIONAL")) {
          next();
        } else if (to.matched.some((record) => record.meta.is_vice_secretario_regional) && user.role.toUpperCase().includes("VICESECRETARIO REGIONAL")) {
          next();
        } else if (to.matched.some((record) => record.meta.is_vice_secretario_provincial) && user.role.toUpperCase().includes("VICESECRETARIO PROVINCIAL")) {
          next();
        } else if (to.matched.some((record) => record.meta.is_director_provincial) && user.role.toUpperCase().includes("DIRECTOR PROVINCIAL")) {
          next();
        } else if (to.matched.some((record) => record.meta.is_encargado_municipal) && user.role.toUpperCase().includes("ENCARGADO MUNICIPAL")) {
          next();
        } else if (to.matched.some((record) => record.meta.is_coordinador) && user.role.toUpperCase().includes("COORDINADOR")) {
          next();
        } else if (to.matched.some((record) => record.meta.is_enlace) && user.role.toUpperCase().includes("ENLACE")) {
          next();
        } else {
          next({ name: "Landing" });
        }
      }
    }
  } else {
    next();
  }
});

export default router;
