<template>
  <v-container v-if="loaded">
    <v-overlay :value="!loading">
      <v-progress-circular indeterminate size="64">Cargando data</v-progress-circular>
    </v-overlay>
    <v-container>
      <filterTextComponent :geography="geography" @filter-changed="handleFilterChange" class="map-size" />
      <div class="controls">
        <input style="width: 70%;" ref="autocompleteInput" type="text" id="autocomplete"
          placeholder="Enter an address" />

        <v-btn @click="addRoute">Agregar ruta</v-btn> <!-- Add route button -->
      </div>
      <div class="selected-addresses">
        <ul>
          <li v-for="(address, index) in selectedAddresses" :key="index">{{ address }}</li>
        </ul>
      </div>
<!--       <v-row>
        <div class="embed-container"><small><a
              href="//3fmultiservices.maps.arcgis.com/apps/Embed/index.html?webmap=f597523cdc974b43aee5349a13cffd2d&extent=-69.9441,18.4609,-69.9017,18.4845&home=true&zoom=true&scale=true&search=true&searchextent=true&details=true&legendlayers=true&active_panel=details&basemap_gallery=true&disable_scroll=true&theme=light"
              style="color:#0000FF;text-align:left" target="_blank">Ver mapa más grande</a></small><br><iframe
            width="500" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title="test map"
            src="//3fmultiservices.maps.arcgis.com/apps/Embed/index.html?webmap=f597523cdc974b43aee5349a13cffd2d&extent=-69.9441,18.4609,-69.9017,18.4845&home=true&zoom=true&previewImage=true&scale=true&search=true&searchextent=true&details=true&legendlayers=true&active_panel=details&basemap_gallery=true&disable_scroll=true&theme=light"></iframe>
        </div>
      </v-row> -->
      <v-row style="margin-top: 2rem;">
        <gm class="map-size" ref="mapComponent" id="gmMap" :destinationAddress="destinationAddress" :routes="routes"
          :markers="markers"></gm>
        <attribTblComponent :tableItems="membersInFilter" style="width: 40%;" v-if="selectedAttrib"
          @row-clicked="handleMemberSelected" />
      </v-row>
    </v-container>

  </v-container>
</template>


<script>
import gm from "@/components/googleMap.vue";
import filterTextComponent from "@/components/filterTextComponent.vue";
import attribTblComponent from "@/components/attributeTableComponent.vue";

import {
  GetGeography,
  GetMembersInRange,
  GetMapGeo,
  GetMapRecint
} from "@/utils/apiRequest.js";
export default {
  components: {
    gm,
    filterTextComponent,
    attribTblComponent,
  },
  data() {
    return {
      loaded: false,
      loading: true,
      qgisSource: "https://qgiscloud.com/ricantar/proyecto_demo",
      selectedAttrib: false,
      autocomplete: null, // Add autocomplete object
      destinationAddress: "",
      selectedAddresses: [], // Array to store selected addresses
      routes: [], // Array to store route information
      autocompleteResults: [],
      membersInFilter: [],
      markers: [],
      center: {
        lat: 18.474109871562145,
        lng: -69.89758783525434,
      },
    }
  },
  methods: {
    getSmallestPopulatedField(selectedValues) {
      // Define the fields in the order of priority
      const fields = [
        "selectedNeighborhood",
        "selectedSector",
        "selectedDistMunicipality",
        "selectedMunicipality",
        "selectedProvince"
      ];

      // Iterate through the fields and return the first populated one
      for (const field of fields) {
        if (selectedValues[field]) {
          // Remove "selected" prefix and convert to lowercase
          const formattedField = field.replace("selected", "").toLowerCase();
          return { field: formattedField, value: selectedValues[field].toUpperCase() };
        }
      }

      // If none of the fields are populated, return an object with empty values
      return { field: "", value: "" };
    },
    drawCoordinates(coordinates) {
      // Check if coordinates is an array and not empty
      if (Array.isArray(coordinates) && coordinates.length > 0) {
        // Create an array to store the LatLng objects
        const polygonPaths = [];

        // Loop through the coordinates and convert them to LatLng objects
        coordinates.forEach((polygon) => {
          const polygonPathsInner = polygon.map((ring) => {
            // Initialize an array to store the LatLng objects for this ring
            const ringPath = [];

            ring.forEach((point) => {
              // Check if the point contains valid and finite coordinates
              const lat = parseFloat(point[1]);
              const lng = parseFloat(point[0]);

              if (!isNaN(lat) && !isNaN(lng) && isFinite(lat) && isFinite(lng)) {
                // Create a LatLng object for valid coordinates
                const latLng = new google.maps.LatLng(lat, lng);
                ringPath.push(latLng);
              } else {
                // Log the problematic coordinates for debugging
                console.error("Invalid coordinates:", point);
              }
            });

            return ringPath;
          });

          polygonPaths.push(polygonPathsInner);
        });



        // Create a Polygon using the LatLng objects
        const polygon = new google.maps.Polygon({
          paths: polygonPaths,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });

        // Assuming you have a reference to your Google Maps instance as this.map
        console.log("almost")
        if (this.$refs.mapComponent && this.$refs.mapComponent.map) {
          polygon.setMap(this.$refs.mapComponent.map);
        }
      }
    },

    drawRecintoMarkers(recintos) {
      // Loop through the recintos array and create markers for each recinto
      recintos.forEach((recinto) => {
        // Extract latitude and longitude from the recinto.location string
        const locationParts = recinto.location.split(',');

        if (locationParts.length === 2) {
          const lat = parseFloat(locationParts[0].trim());
          const lng = parseFloat(locationParts[1].trim());

          if (!isNaN(lat) && !isNaN(lng)) {
            // Create a marker with extracted latitude and longitude
            const newMarker = new google.maps.Marker({
              position: { lat, lng },
              title: recinto.name, // Replace with the recinto's name or any relevant title
              description: recinto.code, // Add custom description property
              // Add other properties as needed
            });

            // Add the new marker to the map
            newMarker.setMap(this.$refs.mapComponent.map);

            // You can also add the marker to an array to manage them later if needed
            this.markers.push(newMarker);

            this.$refs.mapComponent.addMarkers();
          }
        }
      });
    },


    async handleFilterChange(selectedValues) {
      try {
        // You can use the selectedValues object here as needed
        let demarcation = this.getSmallestPopulatedField(selectedValues);

        const response = await GetMapGeo(demarcation);
        if (response && response.status === "success") {
          // Assuming you have a method to draw the response.output.geometry.coordinates
          this.drawCoordinates(response.output[0].geometry.coordinates);
        }

        const recints = await GetMapRecint(demarcation);
        if (recints && recints.status === "success") {
          // Assuming you have a method to draw the response.output.geometry.coordinates
          this.drawRecintoMarkers(recints.recintos);
        }

        // Initialize an array to store all members
        this.membersInFilter = [];

        let currentPage = 1;
        const pageSize = 700000; // Set your desired page size
        let pending = true;

        while (pending) {
          this.loading = false;
          const response = await GetMembersInRange(demarcation, currentPage, pageSize);
          if (response && response.status === "success") {
            const pageMembers = response.members;
            if (pageMembers.length === 0) {
              pending = false;
              break; // No more pages, exit the loop
            }
            this.membersInFilter.push(...pageMembers);
            currentPage++;
          } else {
            pending = false; // Error occurred, exit the loop
          }
        }
        this.selectedAttrib = true;
        this.loading = true;
        this.updateClass();
      } catch (error) {
        console.error("Error in handleFilterChange:", error);
        // Handle the error as needed, e.g., show an error message to the user
      }
      this.loading = true;
    },

    async handleMemberSelected(member) {
      // Triggered when a member is selected in the attribute table
      // Extract latitude and longitude from member.location string
      const locationParts = member.location.split(','); // Assuming the string is in the format "latitude,longitude"

      if (locationParts.length === 2) {
        const lat = parseFloat(locationParts[0].trim());
        const lng = parseFloat(locationParts[1].trim());

        if (!isNaN(lat) && !isNaN(lng)) {
          // Create a marker with extracted latitude and longitude
          const newMarker = new google.maps.Marker({
            position: { lat, lng },
            title: member.nombres, // Replace with the member's name or any relevant title
            description: member.cedula, // Add custom description property
            // Add other properties as needed
          });

          // Add the new marker to the map
          newMarker.setMap(this.$refs.mapComponent.map);

          // Add the marker to the markers array
          this.markers.push(newMarker);

          // Call the addMarkers function in the Google Map component
          this.$refs.mapComponent.addMarkers();
        }
      }
    },

    async getCoordinates() {
      const geocoder = new google.maps.Geocoder();

      // Define a GeocoderRequest object with the address to be geocoded
      const geocoderRequest = {
        address: "182 calle costa rica",
        componentRestrictions: {
          country: "DO", // Filter results to Dominican Republic (DO)
        },
      };

      // Use the geocode method to convert the address to coordinates
      geocoder.geocode(geocoderRequest, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
          // Get the first result (most relevant) and access its geometry location
          const location = results[0].geometry.location;

          // Extract latitude and longitude
          const lat = location.lat();
          const lng = location.lng();

          // Do something with the latitude and longitude
          console.log("Latitude:", lat);
          console.log("Longitude:", lng);
        } else {
          console.error("Geocoding failed:", status);
        }
      });
    },

    updateClass() {
      const gmElement = document.getElementById('gmMap');
      if (this.selectedAttrib) {
        gmElement.classList.remove('map-size');
        gmElement.classList.add('map-size-attr');
      } else {
        gmElement.classList.remove('map-size-attr');
        gmElement.classList.add('map-size');
      }
    },
    initAutocomplete() {
      const input = this.$refs.autocompleteInput;
      this.autocomplete = new google.maps.places.Autocomplete(input, {
        types: ["establishment"], // Restrict to addresses only, you can change this as needed
        componentRestrictions: { country: "DO" },
      });

      // Listen for place selection event
      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();
        const fullAddress = place.formatted_address;
        this.destinationAddress = fullAddress;
        this.autocomplete.set('place', null);
      });
    },
    addRoute() {
      // Prepare origin and destination addresses
      const origin = this.center;
      const destination = this.destinationAddress;

      // Create a DirectionsService instance
      const directionsService = new google.maps.DirectionsService();

      // Define the request for directions from center to destination
      if (this.destinationAddress) {
        const request = {
          origin,
          destination,
          travelMode: google.maps.TravelMode.DRIVING,
        };

        // Use the DirectionsService to calculate the route from center to destination
        directionsService.route(request, (response, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            const route = response.routes[0];
            // Add the route to the routes array
            this.routes.push(route);
            // Clear the destinationAddress for the next calculation
            this.destinationAddress = "";

            // Now, calculate routes for members with valid locations
          }
        });
      }

      this.membersInFilter.forEach((member) => {
        if (member.location && member.electoralLocation) {
          const memberOrigin = member.location;
          const memberDestination = member.electoralLocation;

          // Define the request for directions from member's location to electoralLocation
          const memberRequest = {
            origin: memberOrigin,
            destination: memberDestination,
            travelMode: google.maps.TravelMode.DRIVING,
          };

          // Use the DirectionsService to calculate the route with a timeout
          directionsService.route(memberRequest, (memberResponse, memberStatus) => {
            console.log("creating route for: ", member.cedula)
            // Check if the request took more than 10 seconds
            if (memberStatus === google.maps.DirectionsStatus.OK) {
              const memberRoute = memberResponse.routes[0];
              // Add the member's route to the routes array
              this.routes.push(memberRoute);
            } else if (memberStatus === google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
              // Handle OVER_QUERY_LIMIT error (you can add your logic here)
              console.error('Directions request limit exceeded');
            } else {
              // Handle other errors or timeouts here
              console.error('Directions request failed or timed out');
            }
          });

          // Add a timeout for the request
          setTimeout(() => {
            directionsService.route(memberRequest, () => { });
          }, 100); // 10 seconds timeout
        }
      });
    },

    calculateRoute() {
      if (this.destinationAddress) {
        const request = {
          origin: this.center,
          destination: this.destinationAddress,
          travelMode: "DRIVING", // You can change the travel mode as needed
        };

        this.directionsService.route(request, (result, status) => {
          if (status === "OK") {
            this.directionsDisplay.setDirections(result);
          } else {
            console.error("Error calculating route:", status);
          }
        });
      }
    },

    locateGeoLocation() {
      navigator.geolocation.getCurrentPosition((res) => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
      });

    },
  },
  async mounted() {
    // Load other data
    let resp = await GetGeography();
    if (resp.data && resp.data.output) {
      this.geography = resp.data.output;
    }
    this.loaded = true;

    setTimeout(() => {
      // Initialize the autocomplete and other Google Maps related code
      this.initAutocomplete();
      this.getCoordinates()
    }, 100);
    this.locateGeoLocation()
  }

};

</script>

<style>
.subheading {
  font-size: 20px;
  margin-top: 20px;
}

.map-size-attr {
  width: 110rem;
  max-width: 60%;
}

.map-size {
  width: 110rem;
  max-width: 100%;
}

.controls {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.embed-container {
  position: relative;
  padding-bottom: 80%;
  height: 0;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

small {
  position: absolute;
  z-index: 40;
  bottom: 0;
  margin-bottom: -15px;
}
</style>
