
<template>
  <v-container class="background">
    <v-row>
      <v-col cols="12"> </v-col>
    </v-row>
    <v-form ref="form" @submit.prevent="submitForm">
      <v-row>
        <v-col cols="12" sm="6">
          <h3>Nombre de la junta</h3>
          <v-text-field v-model="councilName" label="Nombre" required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <h3>Presidente</h3>
          <v-text-field v-model="presidente.nombres" label="Nombres" required></v-text-field>
          <v-text-field v-model="presidente.apellidos" label="Apellidos" required></v-text-field>
          <h4>Información de contácto</h4>
          <v-text-field v-model="presidente.direccion.provincia" label="Dirección" required></v-text-field>
          <!-- Add more fields for the Presidente here -->
          <v-text-field v-model="presidente.correo" label="Contácto"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <h3>Vice Presidente</h3>
          <v-text-field v-model="vicePresidente.nombres" label="Nombres" required></v-text-field>
          <v-text-field v-model="vicePresidente.apellidos" label="Apellidos" required></v-text-field>
          <h4>Información de contácto</h4>
          <v-text-field v-model="vicePresidente.direccion.provincia" label="Dirección" required></v-text-field>
          <!-- Add more fields for the Vice Presidente here -->
          <v-text-field v-model="vicePresidente.correo" label="Contácto"></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <h3>Secretario</h3>
          <v-text-field v-model="secretario.nombres" label="Nombres" required></v-text-field>
          <v-text-field v-model="secretario.apellidos" label="Apellidos" required></v-text-field>
          <h4>Información de contácto</h4>
          <v-text-field v-model="secretario.direccion.provincia" label="Dirección" required></v-text-field>
          <!-- Add more fields for the Secretario here -->
          <v-text-field v-model="secretario.correo" label="Contácto"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <h3>Tesorero</h3>
          <v-text-field v-model="tesorero.nombres" label="Nombres" required></v-text-field>
          <v-text-field v-model="tesorero.apellidos" label="Apellidos" required></v-text-field>
          <h4>Información de contácto</h4>
          <v-text-field v-model="tesorero.direccion.provincia" label="Dirección" required></v-text-field>
          <!-- Add more fields for the Tesorero here -->
          <v-text-field v-model="tesorero.correo" label="Contácto"></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <h3>Director de Educación Ciudadana</h3>
          <v-text-field v-model="directorEducacionCiudadana.nombres" label="Nombres" required></v-text-field>
          <v-text-field v-model="directorEducacionCiudadana.apellidos" label="Apellidos" required></v-text-field>
          <h4>Información de contácto</h4>
          <v-text-field v-model="directorEducacionCiudadana.direccion.provincia" label="Dirección"
            required></v-text-field>
          <!-- Add more fields for the Director de Educación Ciudadana here -->
          <v-text-field v-model="directorEducacionCiudadana.correo" label="Contácto"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <h3>Director de Medio Ambiente y Gestión de Riesgo</h3>
          <v-text-field v-model="directorMedioAmbienteGestionRiesgo.nombres" label="Nombres" required></v-text-field>
          <v-text-field v-model="directorMedioAmbienteGestionRiesgo.apellidos" label="Apellidos" required></v-text-field>
          <h4>Información de contácto</h4>
          <v-text-field v-model="directorMedioAmbienteGestionRiesgo.direccion.provincia" label="Dirección"
            required></v-text-field>
          <!-- Add more fields for the Director de Medio Ambiente y Gestión de Riesgo here -->
          <v-text-field v-model="directorMedioAmbienteGestionRiesgo.correo" label="Contácto"></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <h3>Director de Derechos Humanos</h3>
          <v-text-field v-model="directorDerechosHumanos.nombres" label="Nombres" required></v-text-field>
          <v-text-field v-model="directorDerechosHumanos.apellidos" label="Apellidos" required></v-text-field>
          <h4>Información de contácto</h4>
          <v-text-field v-model="directorDerechosHumanos.direccion.provincia" label="Dirección" required></v-text-field>
          <!-- Add more fields for the Director de Derechos Humanos here -->
          <v-text-field v-model="directorDerechosHumanos.correo" label="Contácto"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <h3>Director De Defensoria de los derechos de niño niña y adolecentes</h3>
          <v-text-field v-model="
            directorDefensoriaEspaciosPublicosDerechoTerritorio.nombres
          " label="Nombres" required></v-text-field>
          <v-text-field v-model="
            directorDefensoriaEspaciosPublicosDerechoTerritorio.apellidos
          " label="Apellidos" required></v-text-field>
          <h4>Información de contácto</h4>
          <v-text-field v-model="
            directorDefensoriaEspaciosPublicosDerechoTerritorio.direccion
              .provincia
          " label="Dirección" required></v-text-field>
          <!-- Add more fields for the Director de Defensoría de Espacios Públicos y Derecho al Territorio here -->
          <v-text-field v-model="directorDefensoriaEspaciosPublicosDerechoTerritorio.correo"
            label="Contácto"></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <h3>Director de Salud y Seguridad Social</h3>
          <v-text-field v-model="directorSaludSeguridadSocial.nombres" label="Nombres" required></v-text-field>
          <v-text-field v-model="directorSaludSeguridadSocial.apellidos" label="Apellidos" required></v-text-field>
          <h4>Información de contácto</h4>
          <v-text-field v-model="directorSaludSeguridadSocial.direccion.provincia" label="Dirección"
            required></v-text-field>
          <!-- Add more fields for the Director de Salud y Seguridad Social here -->
          <v-text-field v-model="directorSaludSeguridadSocial.correo" label="Contácto"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <h3>Director de Defensoría de los Derechos de Menores</h3>
          <v-text-field v-model="directorDefensoriaDerechosMenores.nombres" label="Nombres" required></v-text-field>
          <v-text-field v-model="directorDefensoriaDerechosMenores.apellidos" label="Apellidos" required></v-text-field>
          <h4>Información de contácto</h4>
          <v-text-field v-model="directorDefensoriaDerechosMenores.direccion.provincia" label="Dirección"
            required></v-text-field>
          <!-- Add more fields for the Director de Defensoría de los Derechos de Menores here -->
          <v-text-field v-model="directorDefensoriaDerechosMenores.correo" label="Contácto"></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <h3>Director de Animación Sociocultural y Deporte</h3>
          <v-text-field v-model="directorAnimacionSocioculturalDeporte.nombres" label="Nombres" required></v-text-field>
          <v-text-field v-model="directorAnimacionSocioculturalDeporte.apellidos" label="Apellidos"
            required></v-text-field>
          <h4>Información de contácto</h4>
          <v-text-field v-model="directorAnimacionSocioculturalDeporte.direccion.provincia" label="Dirección"
            required></v-text-field>
          <!-- Add more fields for the Director de Animación Sociocultural y Deporte here -->
          <v-text-field v-model="directorAnimacionSocioculturalDeporte.correo" label="Contácto"></v-text-field>
        </v-col>
        <!-- Add more columns for other directors -->
      </v-row>
    </v-form>

    <v-row>
      <v-col cols="12">
        <v-btn @click="submitForm" color="primary">Registrar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { createNeighborhoodCouncil } from "@/utils/apiRequest.js";

export default {
  data() {
    return {
      councilName: "",
      presidente: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Presidente
        },
        correo: "",
      },
      vicePresidente: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Vice Presidente
        },
        correo: "",
      },
      secretario: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Secretario
        },
        correo: "",
      },
      tesorero: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Tesorero
        },
        correo: "",
      },
      directorEducacionCiudadana: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Director de Educación Ciudadana
        },
        correo: "",
      },
      directorMedioAmbienteGestionRiesgo: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Director de Medio Ambiente y Gestión de Riesgo
        },
        correo: "",
      },
      directorDerechosHumanos: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Director de Derechos Humanos
        },
        correo: "",
      },
      directorDefensoriaEspaciosPublicosDerechoTerritorio: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Director de Defensoría de Espacios Públicos y Derecho al Territorio
        },
        correo: "",
      },
      directorSaludSeguridadSocial: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Director de Salud y Seguridad Social
        },
        correo: "",
      },
      directorDefensoriaDerechosMenores: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Director de Defensoría de los Derechos de Menores
        },
        correo: "",
      },
      directorAnimacionSocioculturalDeporte: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Director de Animación Sociocultural y Deporte
        },
        correo: "",
      },
      // Add more properties for other directors
    };
  },
  methods: {
    resetData() {
      this.councilName = "";
      this.presidente = {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Presidente
        },
        correo: "",
      };
      this.vicePresidente = {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Vice Presidente
        },
        correo: "",
      };
      this.secretario = {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Secretario
        },
        correo: "",
      };
      this.tesorero = {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Tesorero
        },
        correo: "",
      };
      this.directorEducacionCiudadana = {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Director de Educación Ciudadana
        },
        correo: "",
      };
      this.directorMedioAmbienteGestionRiesgo = {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Director de Medio Ambiente y Gestión de Riesgo
        },
        correo: "",
      };
      this.directorDerechosHumanos = {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Director de Derechos Humanos
        },
        correo: "",
      };
      this.directorDefensoriaEspaciosPublicosDerechoTerritorio = {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Director de Defensoría de Espacios Públicos y Derecho al Territorio
        },
        correo: "",
      };
      this.directorSaludSeguridadSocial = {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Director de Salud y Seguridad Social
        },
        correo: "",
      };
      this.directorDefensoriaDerechosMenores = {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Director de Defensoría de los Derechos de Menores
        },
        correo: "",
      };
      this.directorAnimacionSocioculturalDeporte = {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
          // Add more address fields for the Director de Animación Sociocultural y Deporte
        },
        correo: "",
      };
      // Add more properties for other directors
    },
    requiredRules(field) {
      return [(v) => !!v || `${field} es obligatorio`];
    },
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const neighborhoodCouncil = {
        nombre: this.councilName,
        presidente: this.presidente,
        vicePresidente: this.vicePresidente,
        secretario: this.secretario,
        tesorero: this.tesorero,
        directorEducacionCiudadana: this.directorEducacionCiudadana,
        directorMedioAmbienteGestionRiesgo:
          this.directorMedioAmbienteGestionRiesgo,
        directorDerechosHumanos: this.directorDerechosHumanos,
        directorDefensoriaEspaciosPublicosDerechoTerritorio:
          this.directorDefensoriaEspaciosPublicosDerechoTerritorio,
        directorSaludSeguridadSocial: this.directorSaludSeguridadSocial,
        directorDefensoriaDerechosMenores:
          this.directorDefensoriaDerechosMenores,
        directorAnimacionSocioculturalDeporte:
          this.directorAnimacionSocioculturalDeporte,
        // Add more properties for other directors
      };

      const response = await createNeighborhoodCouncil(neighborhoodCouncil);

      if (response && response.data.status === "success") {
        // Handle success response
        console.log("Neighborhood Council created successfully");
      } else {
        // Handle error response
        console.error("Failed to create Neighborhood Council");
      }
    },
  },
};
</script>
  
<style scoped>
.background {
  background-image: url("../assets/logoFPwTransparent.jpg");
  background-position: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
  