<template>
  <v-container class="background">
    <v-alert type="success" :value="success"
      >junta actualizado con éxito
    </v-alert>
    <v-alert type="error" :value="failed"
      >Error actualizando junta: {{ error }}
    </v-alert>
    <v-form ref="form" @submit.prevent="submitForm">
      <v-card class="mx-auto" variant="outlined" style="padding-top: 1rem">
        <v-row style="padding-left: 1rem; padding-right: 1rem">
          <v-col cols="12" sm="6">
            <h2>Nombre de la junta</h2>
            <v-text-field
              v-model="councilName"
              label="Nombre"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row style="padding-left: 1rem; padding-right: 1rem">
          <v-col cols="12" sm="6">
            <h2>Presidente</h2>
            <v-text-field
              v-model="presidente.nombres"
              label="Nombres"
              required
            ></v-text-field>
            <v-text-field
              v-model="presidente.apellidos"
              label="Apellidos"
              required
            ></v-text-field>
            <h4>Información de contácto</h4>
            <v-text-field
              v-model="presidente.direccion.provincia"
              label="Dirección"
              required
            ></v-text-field>
            <v-text-field
              v-model="presidente.correo"
              label="Contácto"
            ></v-text-field>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="6">
            <h2>Vice Presidente</h2>
            <v-text-field
              v-model="vicePresidente.nombres"
              label="Nombres"
              required
            ></v-text-field>
            <v-text-field
              v-model="vicePresidente.apellidos"
              label="Apellidos"
              required
            ></v-text-field>
            <h4>Información de contácto</h4>
            <v-text-field
              v-model="vicePresidente.direccion.provincia"
              label="Dirección"
              required
            ></v-text-field>
            <v-text-field
              v-model="vicePresidente.correo"
              label="Contácto"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mx-auto" variant="outlined" style="margin-top: 1rem">
        <v-row style="padding-left: 1rem; padding-right: 1rem">
          <v-col cols="12" sm="6">
            <h2>Secretario</h2>
            <v-text-field
              v-model="secretario.nombres"
              label="Nombres"
              required
            ></v-text-field>
            <v-text-field
              v-model="secretario.apellidos"
              label="Apellidos"
              required
            ></v-text-field>
            <h4>Información de contácto</h4>
            <v-text-field
              v-model="secretario.direccion.provincia"
              label="Dirección"
              required
            ></v-text-field>
            <v-text-field
              v-model="secretario.correo"
              label="Contácto"
            ></v-text-field>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="6">
            <h2>Tesorero</h2>
            <v-text-field
              v-model="tesorero.nombres"
              label="Nombres"
              required
            ></v-text-field>
            <v-text-field
              v-model="tesorero.apellidos"
              label="Apellidos"
              required
            ></v-text-field>
            <h4>Información de contácto</h4>
            <v-text-field
              v-model="tesorero.direccion.provincia"
              label="Dirección"
              required
            ></v-text-field>
            <v-text-field
              v-model="tesorero.correo"
              label="Contácto"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="mx-auto" variant="outlined" style="margin-top: 1rem">
        <v-row style="padding-left: 1rem; padding-right: 1rem">
          <v-col cols="12" sm="6">
            <h2>Director de Educación Ciudadana</h2>
            <v-text-field
              v-model="directorEducacionCiudadana.nombres"
              label="Nombres"
              required
            ></v-text-field>
            <v-text-field
              v-model="directorEducacionCiudadana.apellidos"
              label="Apellidos"
              required
            ></v-text-field>
            <h4>Información de contácto</h4>
            <v-text-field
              v-model="directorEducacionCiudadana.direccion.provincia"
              label="Dirección"
              required
            ></v-text-field>
            <v-text-field
              v-model="directorEducacionCiudadana.correo"
              label="Contácto"
            ></v-text-field>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="6">
            <h2>Director de Medio Ambiente y Gestión de Riesgo</h2>
            <v-text-field
              v-model="directorMedioAmbienteGestionRiesgo.nombres"
              label="Nombres"
              required
            ></v-text-field>
            <v-text-field
              v-model="directorMedioAmbienteGestionRiesgo.apellidos"
              label="Apellidos"
              required
            ></v-text-field>
            <h4>Información de contácto</h4>
            <v-text-field
              v-model="directorMedioAmbienteGestionRiesgo.direccion.provincia"
              label="Dirección"
              required
            ></v-text-field>
            <v-text-field
              v-model="directorMedioAmbienteGestionRiesgo.correo"
              label="Contácto"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mx-auto" variant="outlined" style="margin-top: 1rem">
        <v-row style="padding-left: 1rem; padding-right: 1rem">
          <v-col cols="12" sm="6">
            <h2>Director de Derechos Humanos</h2>
            <v-text-field
              v-model="directorDerechosHumanos.nombres"
              label="Nombres"
              required
            ></v-text-field>
            <v-text-field
              v-model="directorDerechosHumanos.apellidos"
              label="Apellidos"
              required
            ></v-text-field>
            <h4>Información de contácto</h4>
            <v-text-field
              v-model="directorDerechosHumanos.direccion.provincia"
              label="Dirección"
              required
            ></v-text-field>
            <v-text-field
              v-model="directorDerechosHumanos.correo"
              label="Contácto"
            ></v-text-field>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="6">
            <h2>
              Director De Defensoria de los derechos de niño niña y adolecentes
            </h2>
            <v-text-field
              v-model="
                directorDefensoriaEspaciosPublicosDerechoTerritorio.nombres
              "
              label="Nombres"
              required
            ></v-text-field>
            <v-text-field
              v-model="
                directorDefensoriaEspaciosPublicosDerechoTerritorio.apellidos
              "
              label="Apellidos"
              required
            ></v-text-field>
            <h4>Información de contácto</h4>
            <v-text-field
              v-model="
                directorDefensoriaEspaciosPublicosDerechoTerritorio.direccion
                  .provincia
              "
              label="Dirección"
              required
            ></v-text-field>
            <v-text-field
              v-model="
                directorDefensoriaEspaciosPublicosDerechoTerritorio.correo
              "
              label="Contácto"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="mx-auto" variant="outlined" style="margin-top: 1rem">
        <v-row style="padding-left: 1rem; padding-right: 1rem">
          <v-col cols="12" sm="6">
            <h2>Director de Salud y Seguridad Social</h2>
            <v-text-field
              v-model="directorSaludSeguridadSocial.nombres"
              label="Nombres"
              required
            ></v-text-field>
            <v-text-field
              v-model="directorSaludSeguridadSocial.apellidos"
              label="Apellidos"
              required
            ></v-text-field>
            <h4>Información de contácto</h4>
            <v-text-field
              v-model="directorSaludSeguridadSocial.direccion.provincia"
              label="Dirección"
              required
            ></v-text-field>
            <v-text-field
              v-model="directorSaludSeguridadSocial.correo"
              label="Contácto"
            ></v-text-field>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="6">
            <h2>Director de Defensoría de los Derechos de Menores</h2>
            <v-text-field
              v-model="directorDefensoriaDerechosMenores.nombres"
              label="Nombres"
              required
            ></v-text-field>
            <v-text-field
              v-model="directorDefensoriaDerechosMenores.apellidos"
              label="Apellidos"
              required
            ></v-text-field>
            <h4>Información de contácto</h4>
            <v-text-field
              v-model="directorDefensoriaDerechosMenores.direccion.provincia"
              label="Dirección"
              required
            ></v-text-field>
            <v-text-field
              v-model="directorDefensoriaDerechosMenores.correo"
              label="Contácto"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="mx-auto" variant="outlined" style="margin-top: 1rem">
        <v-row style="padding-left: 1rem; padding-right: 1rem">
          <v-col cols="12" sm="6">
            <h2>Director de Animación Sociocultural y Deporte</h2>
            <v-text-field
              v-model="directorAnimacionSocioculturalDeporte.nombres"
              label="Nombres"
              required
            ></v-text-field>
            <v-text-field
              v-model="directorAnimacionSocioculturalDeporte.apellidos"
              label="Apellidos"
              required
            ></v-text-field>
            <h4>Información de contácto</h4>
            <v-text-field
              v-model="
                directorAnimacionSocioculturalDeporte.direccion.provincia
              "
              label="Dirección"
              required
            ></v-text-field>
            <v-text-field
              v-model="directorAnimacionSocioculturalDeporte.correo"
              label="Contácto"
            ></v-text-field>
          </v-col>
          <!-- Add more columns for other directors -->
        </v-row>
      </v-card>

      <v-row>
        <v-col cols="12">
          <v-btn @click="submitForm" color="primary">Registrar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import {
  updateNeighborhoodCouncils,
  fetcNeighborhoodCouncil,
} from "@/utils/apiRequest.js";
export default {
  data() {
    return {
      success: false,
      failed: false,
      error: "",
      councilName: "",
      presidente: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
        },
        correo: "",
      },
      vicePresidente: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
        },
        correo: "",
      },
      secretario: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
        },
        correo: "",
      },
      tesorero: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
        },
        correo: "",
      },
      directorEducacionCiudadana: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
        },
        correo: "",
      },
      directorMedioAmbienteGestionRiesgo: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
        },
        correo: "",
      },
      directorDerechosHumanos: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
        },
        correo: "",
      },
      directorDefensoriaEspaciosPublicosDerechoTerritorio: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
        },
        correo: "",
      },
      directorSaludSeguridadSocial: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
        },
        correo: "",
      },
      directorDefensoriaDerechosMenores: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
        },
        correo: "",
      },
      directorAnimacionSocioculturalDeporte: {
        nombres: "",
        apellidos: "",
        direccion: {
          provincia: "",
        },
        correo: "",
      },
    };
  },
  created() {
    // Fetch the user data by ID and populate the fields
    this.fetchUserById(this.$route.params.id);
  },
  methods: {
    async fetchUserById(userId) {
      let response = await fetcNeighborhoodCouncil(userId);
      if (response) {
        if (response.data.status != "failed") {
          this.councilName = response.data.output.nombre;
          this.presidente = { ...response.data.output.presidente };
          this.vicePresidente = { ...response.data.output.vicePresidente };
          this.secretario = { ...response.data.output.secretario };
          this.tesorero = { ...response.data.output.tesorero };
          this.directorEducacionCiudadana = {
            ...response.data.output.directorEducacionCiudadana,
          };
          this.directorMedioAmbienteGestionRiesgo = {
            ...response.data.output.directorMedioAmbienteGestionRiesgo,
          };
          this.directorDerechosHumanos = {
            ...response.data.output.directorDerechosHumanos,
          };
          this.directorDefensoriaEspaciosPublicosDerechoTerritorio = {
            ...response.data.output
              .directorDefensoriaEspaciosPublicosDerechoTerritorio,
          };
          this.directorSaludSeguridadSocial = {
            ...response.data.output.directorSaludSeguridadSocial,
          };
          this.directorDefensoriaDerechosMenores = {
            ...response.data.output.directorDefensoriaDerechosMenores,
          };
          this.directorAnimacionSocioculturalDeporte = {
            ...response.data.output.directorAnimacionSocioculturalDeporte,
          };
          return;
        }
        this.failed = true;
        this.success = false;
        this.error = response.data.error;
      }
    },
    async submitForm() {
      let council = {
        ID: this.$route.params.id,
        nombre: this.councilName,
        presidente: this.presidente,
        vicePresidente: this.vicePresidente,
        secretario: this.secretario,
        tesorero: this.tesorero,
        directorEducacionCiudadana: this.directorEducacionCiudadana,
        directorMedioAmbienteGestionRiesgo:
          this.directorMedioAmbienteGestionRiesgo,
        directorDerechosHumanos: this.directorDerechosHumanos,
        directorDefensoriaEspaciosPublicosDerechoTerritorio:
          this.directorDefensoriaEspaciosPublicosDerechoTerritorio,
        directorSaludSeguridadSocial: this.directorSaludSeguridadSocial,
        directorDefensoriaDerechosMenores:
          this.directorDefensoriaDerechosMenores,
        directorAnimacionSocioculturalDeporte:
          this.directorAnimacionSocioculturalDeporte,
      };
      let response = await updateNeighborhoodCouncils(council);
      if (response) {
        if (response.data.status != "failed") {
          this.failed = false;
          this.success = true;
          this.error = "";
          return;
        }
        this.failed = true;
        this.success = false;
        this.error = response.data.error;
        return;
      }
      this.failed = true;
      this.success = false;
      this.error = "error en el servidor, intente más tarde";
    },
  },
};
</script>