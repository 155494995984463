<template>
  <v-container>
    <h1>Editar miembro</h1>
    <v-card-text>
      <member-edit></member-edit>
    </v-card-text>
  </v-container>
</template>
  
<script>
import MemberEdit from "@/components/editMember.vue";

export default {
  components: {
    MemberEdit,
  },
};
</script>