<template>
  <v-toolbar style="height: 8rem;">
    <v-row style="width: 90%;padding-left: 1rem;padding-top: 1rem;">
      <v-row style="padding-top: 2%;">
        <v-col cols="2">
          <v-autocomplete v-model="selectedProvince" class="select-item" :items="provinces" label="Provincia" dense
            hide-details></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete v-model="selectedMunicipality" class="select-item" :items="municipalities" label="Municipio"
            dense hide-details></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete v-model="selectedDistMunicipality" class="select-item" :items="distritosMunicipales"
            label="Distrito municipal" dense hide-details></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete v-model="selectedSector" class="select-item" :items="sectors" label="Sector" dense
            hide-details></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete v-model="selectedNeighborhood" class="select-item" :items="neighborhoods" label="Barrio" dense
            hide-details></v-autocomplete>
        </v-col>
        <v-col cols="10">
          <v-text-field v-model="searchTerm" label="Buscar por nombre" class="select-item" dense
            hide-details></v-text-field>
        </v-col>
      </v-row>
    </v-row>
    <v-row style="width:5%;margin-left: -10%; padding-right: 2%;">
      <v-btn class="btn-apply" color="primary" block large @click="emitSelectedValues">Aplicar</v-btn>
    </v-row>
  </v-toolbar>
</template> 

<script>
export default {
  name: "FilterBar",
  props: {
    geography: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedProvince: "",
      selectedMunicipality: "",
      selectedDistMunicipality: "",
      selectedSector: "",
      selectedNeighborhood: "",
      searchTerm: "",
    };
  },
  methods: {
    triggerFilter() {
      this.$emit('trigger-filter', true);
    },
    emitSelectedValues() {
      // Create an object containing the selected values
      const selectedValues = {
        selectedProvince: this.selectedProvince,
        selectedMunicipality: this.selectedMunicipality,
        selectedDistMunicipality: this.selectedDistMunicipality,
        selectedSector: this.selectedSector,
        selectedNeighborhood: this.selectedNeighborhood,
        searchTerm: this.searchTerm,
      };

      // Emit an event with the selected values
      this.$emit('filter-changed', selectedValues);
    },
  },
  computed: {
    provinces() {
      if (this.geography && this.geography.length > 0) {
        const allProvincias = this.geography.flatMap((region) =>
          Object.values(region.provincia).map((provincia) => ({
            text: provincia.nombre,
            value: provincia.nombre,
          }))
        );

        return allProvincias;
      } else {
        return [];
      }
    },
    municipalities() {
      if (
        this.selectedProvince &&
        this.geography &&
        this.geography.length > 0
      ) {
        const provinciaCodigo = this.selectedProvince;
        const allMunicipios = this.geography.flatMap((region) =>
          Object.values(region.provincia).flatMap((provincia) => {
            if (provincia.nombre === provinciaCodigo) {
              return Object.values(provincia.municipio).map((municipio) => ({
                text: municipio.nombre,
                value: municipio.nombre,
              }));
            }
            return [];
          })
        );

        return allMunicipios;
      } else {
        return [];
      }
    },
    distritosMunicipales() {
      if (
        this.selectedMunicipality &&
        this.selectedProvince &&
        this.geography &&
        this.geography.length > 0
      ) {
        const provinciaCodigo = this.selectedProvince;
        const municipioCodigo = this.selectedMunicipality;
        const allDistritosMunicipales = this.geography.flatMap((region) =>
          Object.values(region.provincia).flatMap((provincia) => {
            if (provincia.nombre === provinciaCodigo) {
              return Object.values(provincia.municipio).flatMap((municipio) => {
                if (municipio.nombre === municipioCodigo) {
                  return Object.values(municipio.distMunicipal).map(
                    (distritoMunicipal) => ({
                      text: distritoMunicipal.nombre,
                      value: distritoMunicipal.nombre,
                    })
                  );
                }
                return [];
              });
            }
            return [];
          })
        );

        return allDistritosMunicipales;
      } else {
        return [];
      }
    },
    sectors() {
      if (
        this.selectedDistMunicipality &&
        this.selectedMunicipality &&
        this.selectedProvince &&
        this.geography &&
        this.geography.length > 0
      ) {
        const provinciaCodigo = this.selectedProvince;
        const municipioCodigo = this.selectedMunicipality;
        const distritoMunicipalCodigo = this.selectedDistMunicipality;
        const allSecciones = this.geography.flatMap((region) =>
          Object.values(region.provincia).flatMap((provincia) => {
            if (provincia.nombre === provinciaCodigo) {
              return Object.values(provincia.municipio).flatMap((municipio) => {
                if (municipio.nombre === municipioCodigo) {
                  return Object.values(municipio.distMunicipal).flatMap(
                    (distritoMunicipal) => {
                      if (
                        distritoMunicipal.nombre === distritoMunicipalCodigo
                      ) {
                        return Object.values(distritoMunicipal.sección).map(
                          (seccion) => ({
                            text: seccion.nombre,
                            value: seccion.nombre,
                          })
                        );
                      }
                      return [];
                    }
                  );
                }
                return [];
              });
            }
            return [];
          })
        );

        return allSecciones;
      } else {
        return [];
      }
    },
    neighborhoods() {
      if (
        this.selectedSector &&
        this.selectedDistMunicipality &&
        this.selectedMunicipality &&
        this.selectedProvince &&
        this.geography &&
        this.geography.length > 0
      ) {
        const provinciaCodigo = this.selectedProvince;
        const municipioCodigo = this.selectedMunicipality;
        const distritoMunicipalCodigo = this.selectedDistMunicipality;
        const seccionCodigo = this.selectedSector;
        const allBarrios = this.geography.flatMap((region) =>
          Object.values(region.provincia).flatMap((provincia) => {
            if (provincia.nombre === provinciaCodigo) {
              return Object.values(provincia.municipio).flatMap((municipio) => {
                if (municipio.nombre === municipioCodigo) {
                  return Object.values(municipio.distMunicipal).flatMap(
                    (distritoMunicipal) => {
                      if (
                        distritoMunicipal.nombre === distritoMunicipalCodigo
                      ) {
                        return Object.values(distritoMunicipal.sección).flatMap(
                          (seccion) => {
                            if (seccion.nombre === seccionCodigo) {
                              // Combine 'barrios' and 'SubBarrios' into 'barrios'
                              const combinedBarrios = {
                                ...seccion.barrios,
                                ...seccion.SubBarrios,
                              };
                              return Object.values(combinedBarrios).map(
                                (barrio) => ({
                                  text: barrio.nombre,
                                  value: barrio.nombre,
                                })
                              );
                            }
                            return [];
                          }
                        );
                      }
                      return [];
                    }
                  );
                }
                return [];
              });
            }
            return [];
          })
        );

        return allBarrios;
      } else {
        return [];
      }
    },
  },
};
</script>

<style>
.btn-apply {
  height: 20rem;
}

.v-toolbar {
  box-shadow: 0px 10px 15px rgba(255, 0, 0, 0.2);
  /* Adjust the RGBA values to get the desired shade of red */
}

.select-item {
  font-weight: bold;
}
</style>