<template>
  <v-container v-if="ready" style="margin-left: 2rem; margin-right: 2rem">
    <v-alert type="success" :value="success"
      >Miembro actualizado con éxito
    </v-alert>
    <v-alert type="error" :value="failed"
      >Error actualizando miembro: {{ error }}
    </v-alert>
    <v-form ref="form" @submit.prevent="updateCurrentMember">
      <v-row>
        <v-col cols="6">
          <strong class="tittle">ID:</strong>
          <span class="memberValue">{{ member.ID }}</span>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Cedula:</strong>
          <v-text-field
            :rules="requiredRules('cédula')"
            v-model="editedMember.cedula"
            :counter="11"
            @keyup="sanitizeInput('cedula', 11)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Nombres:</strong>
          <v-text-field
            :rules="requiredRules('nombres')"
            v-model="editedMember.nombres"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Apellidos:</strong>
          <v-text-field
            :rules="requiredRules('apellidos')"
            v-model="editedMember.apellidos"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Fecha Nacimiento:</strong>
          <v-text-field
            :rules="requiredRules('fecha de nacimiento')"
            v-model="editedMember.fechaNacimiento"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Sexo:</strong>
          <v-select
            :rules="requiredRules('sexo')"
            v-model="editedMember.sexo"
            label="SEXO"
            :items="sexos"
            required
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Telefono Casa:</strong>
          <v-text-field v-model="editedMember.telefonoCasa"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Celular 1:</strong>
          <v-text-field
            v-model="editedMember.celular1"
            @keyup="sanitizeInput('celular1', 11)"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Celular 2:</strong>
          <v-text-field
            v-model="editedMember.celular2"
            @keyup="sanitizeInput('celular2', 11)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Provincia:</strong>
          <v-autocomplete
            :rules="requiredRules('provincia')"
            :items="provincias"
            v-model="editedMember.direccion.provincia"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Municipio:</strong>
          <v-autocomplete
            :rules="requiredRules('municipio')"
            :items="municipios"
            v-model="editedMember.direccion.municipio"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Distrito Municipal:</strong>
          <v-autocomplete
            :rules="requiredRules('distrito municipal')"
            :items="distritosMunicipales"
            v-model="editedMember.direccion.distritoMunicipal"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Seccion:</strong>
          <v-autocomplete
            :rules="requiredRules('sección')"
            :items="secciones"
            v-model="editedMember.direccion.seccion"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6"> </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Barrio:</strong>
          <v-autocomplete
            :rules="requiredRules('barrio')"
            :items="barrios"
            v-model="editedMember.direccion.barrio"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Direccion Calle:</strong>
          <v-text-field v-model="editedMember.direccion.calle"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Número de vivienda:</strong>
          <v-text-field v-model="editedMember.direccion.numero"></v-text-field>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Número de apartamento:</strong>
          <v-text-field
            style="font-weight: bold"
            v-model="editedMember.direccion.apto"
            label="Número de apartamento"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-flex style="padding-left: 4rem">
          <googleMapComponent
            style="width: 50rem; height: 30rem"
            ref="mapComponent"
            :markers="markers"
            :focusOnPerson="true"
          />
        </v-flex>
      </v-row>
    </v-form>

    <v-row>
      <v-col>
        <v-checkbox
          v-model="updateLocation"
          label="Actualizar ubicación"
        ></v-checkbox>
        <v-btn color="primary" @click="updateCurrentMember">Actualizar</v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col style="padding-top: 5rem">
        <v-btn
          v-if="isAdmin"
          color="alert"
          style="color: white"
          @click="deleteCurrentMember"
          >Eliminar</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
  
  <script>
import {
  fetchMember,
  updateMember,
  GetGeography,
  deleteMember,
} from "@/utils/apiRequest.js";
import { getWithExpiry } from "@/utils/apiRequest";
import googleMapComponent from "@/components/googleMap.vue";

export default {
  components: { googleMapComponent },
  data() {
    return {
      member: null,
      ready: false,
      editedMember: null,
      isAdmin: false,
      frontPhotoFile: null,
      backPhotoFile: null,
      frontPhotoFileImg: null,
      markers: [],
      backPhotoFileImg: null,
      updateLocation: false,
      error: "",
      userCoordinate: "",
      userLocation: {
        lat: 0,
        lng: 0,
      },
      success: false,
      failed: false,
      sexos: ["Hombre", "Mujer", "Prefiero no decir"],
      cargos: [
        "VICESECRETARIO NACIONAL",
        "VICESECRETARIO REGIONAL",
        "VICESECRETARIO PROVINCIAL",
        "DIRECTOR PROVINCIAL",
        "ENCARGADO MUNICIPAL",
        "COORDINADOR",
        "ENLACE",
      ],
      geography: {},
    };
  },
  async mounted() {
    const memberId = this.$route.params.id;
    let response = await fetchMember(memberId);
    if (response) {
      this.member = response.data;
      this.editedMember = { ...this.member }; // Create a copy for editing
      this.editedMember.fechaNacimiento = this.formatDate(
        this.editedMember.fechaNacimiento
      );
      let coordinate = this.editedMember.location.split(",");
      var lat = parseFloat(coordinate[0]);
      var lng = parseFloat(coordinate[1]);

      this.userLocation.lat = lat
      this.userLocation.lng = lng
      var newMarker = new google.maps.Marker({
        position: { lat, lng },
        title: this.editedMember.nombre, // Replace with the recinto's name or any relevant title
      });

      this.markers.push(newMarker);
      setTimeout(() => {
        // Initialize the autocomplete and other Google Maps related code

        var newMarker = new google.maps.Marker({
          position: { lat, lng },
          title: this.editedMember.nombre, // Replace with the recinto's name or any relevant title
        });

        // Add the new marker to the map
        // You can also add the marker to an array to manage them later if needed

        newMarker.setMap(this.$refs.mapComponent.map);
        this.$refs.mapComponent.addMarkers();
      }, 300);

      this.ready = true;
    }

    let resp = await GetGeography();
    if (resp.data) {
      if (resp.data.output) {
        this.geography = resp.data.output;
      }
    }

    let user = JSON.parse(getWithExpiry("user"));
    if (user.role.toUpperCase().includes("ADMIN")) {
      this.isAdmin = true;
    }
  },
  computed: {
    getFrontPhotoDataURI() {
      return this.frontPhotoFile
        ? URL.createObjectURL(this.frontPhotoFile)
        : `data:image/jpeg;base64,${this.member.cedulaFotos.front}`;
    },
    getBackPhotoDataURI() {
      return this.backPhotoFile
        ? URL.createObjectURL(this.backPhotoFile)
        : `data:image/jpeg;base64,${this.member.cedulaFotos.back}`;
    },
    provincias() {
      if (this.geography && this.geography.length > 0) {
        const allProvincias = this.geography.flatMap((region) =>
          Object.values(region.provincia).map((provincia) => ({
            text: provincia.nombre,
            value: provincia.nombre,
          }))
        );

        return allProvincias;
      } else {
        return [];
      }
    },
    municipios() {
      if (
        this.editedMember.direccion.provincia &&
        this.geography &&
        this.geography.length > 0
      ) {
        const provinciaCodigo = this.editedMember.direccion.provincia;
        const allMunicipios = this.geography.flatMap((region) =>
          Object.values(region.provincia).flatMap((provincia) => {
            if (provincia.nombre === provinciaCodigo) {
              return Object.values(provincia.municipio).map((municipio) => ({
                text: municipio.nombre,
                value: municipio.nombre,
              }));
            }
            return [];
          })
        );

        return allMunicipios;
      } else {
        return [];
      }
    },
    distritosMunicipales() {
      if (
        this.editedMember.direccion.municipio &&
        this.editedMember.direccion.provincia &&
        this.geography &&
        this.geography.length > 0
      ) {
        const provinciaCodigo = this.editedMember.direccion.provincia;
        const municipioCodigo = this.editedMember.direccion.municipio;
        const allDistritosMunicipales = this.geography.flatMap((region) =>
          Object.values(region.provincia).flatMap((provincia) => {
            if (provincia.nombre === provinciaCodigo) {
              return Object.values(provincia.municipio).flatMap((municipio) => {
                if (municipio.nombre === municipioCodigo) {
                  return Object.values(municipio.distMunicipal).map(
                    (distritoMunicipal) => ({
                      text: distritoMunicipal.nombre,
                      value: distritoMunicipal.nombre,
                    })
                  );
                }
                return [];
              });
            }
            return [];
          })
        );

        return allDistritosMunicipales;
      } else {
        return [];
      }
    },
    secciones() {
      if (
        this.editedMember.direccion.distritoMunicipal &&
        this.editedMember.direccion.municipio &&
        this.editedMember.direccion.provincia &&
        this.geography &&
        this.geography.length > 0
      ) {
        const provinciaCodigo = this.editedMember.direccion.provincia;
        const municipioCodigo = this.editedMember.direccion.municipio;
        const distritoMunicipalCodigo =
          this.editedMember.direccion.distritoMunicipal;
        const allSecciones = this.geography.flatMap((region) =>
          Object.values(region.provincia).flatMap((provincia) => {
            if (provincia.nombre === provinciaCodigo) {
              return Object.values(provincia.municipio).flatMap((municipio) => {
                if (municipio.nombre === municipioCodigo) {
                  return Object.values(municipio.distMunicipal).flatMap(
                    (distritoMunicipal) => {
                      if (
                        distritoMunicipal.nombre === distritoMunicipalCodigo
                      ) {
                        return Object.values(distritoMunicipal.sección).map(
                          (seccion) => ({
                            text: seccion.nombre,
                            value: seccion.nombre,
                          })
                        );
                      }
                      return [];
                    }
                  );
                }
                return [];
              });
            }
            return [];
          })
        );

        return allSecciones;
      } else {
        return [];
      }
    },
    barrios() {
      if (
        this.editedMember.direccion.seccion &&
        this.editedMember.direccion.distritoMunicipal &&
        this.editedMember.direccion.municipio &&
        this.editedMember.direccion.provincia &&
        this.geography &&
        this.geography.length > 0
      ) {
        const provinciaCodigo = this.editedMember.direccion.provincia;
        const municipioCodigo = this.editedMember.direccion.municipio;
        const distritoMunicipalCodigo =
          this.editedMember.direccion.distritoMunicipal;
        const seccionCodigo = this.editedMember.direccion.seccion;
        const allBarrios = this.geography.flatMap((region) =>
          Object.values(region.provincia).flatMap((provincia) => {
            if (provincia.nombre === provinciaCodigo) {
              return Object.values(provincia.municipio).flatMap((municipio) => {
                if (municipio.nombre === municipioCodigo) {
                  return Object.values(municipio.distMunicipal).flatMap(
                    (distritoMunicipal) => {
                      if (
                        distritoMunicipal.nombre === distritoMunicipalCodigo
                      ) {
                        return Object.values(distritoMunicipal.sección).flatMap(
                          (seccion) => {
                            if (seccion.nombre === seccionCodigo) {
                              // Combine 'barrios' and 'SubBarrios' into 'barrios'
                              const combinedBarrios = {
                                ...seccion.barrios,
                                ...seccion.SubBarrios,
                              };
                              return Object.values(combinedBarrios).map(
                                (barrio) => ({
                                  text: barrio.nombre,
                                  value: barrio.nombre,
                                })
                              );
                            }
                            return [];
                          }
                        );
                      }
                      return [];
                    }
                  );
                }
                return [];
              });
            }
            return [];
          })
        );

        return allBarrios;
      } else {
        return [];
      }
    },
  },
  methods: {
    sanitizeInput(field, maxLength) {
      // Get the input value
      let inputField = this.editedMember[field];
      // Remove all characters that are not numbers
      inputField = inputField.replace(/[^0-9]/g, "");
      // Update the editedMember property by creating a new object
      if (inputField.length > maxLength) {
        inputField = inputField.substring(0, maxLength); // Truncate to max length
      }
      this.editedMember = {
        ...this.editedMember,
        [field]: inputField,
      };
    },
    formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const isYearFirst = parts[0].length === 4; // Check if year is in the first position
        const day = parts[isYearFirst ? 2 : 0];
        const month = parts[1];
        const year = isYearFirst ? parts[0] : parts[2];
        return `${year}-${month}-${day}`;
      } else {
        return inputDate;
      }
    },

    locateGeoLocation: async function () {
      try {
        // Request geolocation permission
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });

        if (permissionStatus.state === "granted") {
          // Permission already granted, proceed with getting location
          await this.getCurrentLocation();
        } else if (permissionStatus.state === "prompt") {
          // Permission not yet granted, ask the user for permission
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
              (position) => resolve(position),
              (error) => reject(error),
              { enableHighAccuracy: true } // Request high accuracy location
            );
          });
          await this.getCurrentLocation(position);
        } else {
          // Permission denied
          console.log("Geolocation permission denied.");
        }
      } catch (error) {
        console.log("Error checking geolocation permission:", error);
      }
    },

    getCurrentLocation: async function (position) {
      const success = async (position) => {
        this.userLocation.lat = position.coords.latitude;
        this.userLocation.lng = position.coords.longitude;
        this.userCoordinate =
          position.coords.latitude + "," + position.coords.longitude;
      };

      const error = (err) => {
        console.log(err);
      };

      if (position) {
        // Use the provided position if available
        success(position);
      } else {
        navigator.geolocation.getCurrentPosition(success, error, {
          enableHighAccuracy: true, // Request high accuracy location
        });
      }
    },
    async encodePhoto(photo) {
      if (photo == "") {
        return "";
      }
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const encodedData = reader.result.replace(/^data:(.*;base64,)?/, "");
          if (encodedData) {
            resolve(encodedData);
          } else {
            reject(new Error("Failed to encode photo"));
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(photo);
      });
    },
    async updateCurrentMember() {
      if (!this.$refs.form.validate()) {
        return;
      }
      // Perform the update operation
      const frontPhotoFile = this.frontPhotoFileImg
        ? this.frontPhotoFileImg
        : this.getFrontPhotoFile();
      const backPhotoFile = this.backPhotoFileImg
        ? this.backPhotoFileImg
        : this.getBackPhotoFile();

      this.editedMember.cedulaFotos.front = frontPhotoFile;
      this.editedMember.cedulaFotos.back = backPhotoFile;

      if (this.updateLocation == true) {
        await this.locateGeoLocation();
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      const mapComponent = this.$refs.mapComponent;

      // Assuming your googleMapComponent has a method called addMarker
      mapComponent.addNewMarker({
        lat: this.userLocation.lat, // New latitude
        lng: this.userLocation.lng, // New longitude
      });
      this.editedMember.location = this.userCoordinate;
      let response = await updateMember(this.editedMember);
      if (response) {
        if (response.data.status != "failed") {
          // Update the member object with the edited values
          this.member = {
            ...this.editedMember,
            cedulaFotos: {
              front: await this.encodePhoto(frontPhotoFile),
              back: await this.encodePhoto(backPhotoFile),
            },
          };
          this.failed = false;
          this.success = true;
          this.error = "";
          return;
        }
        this.failed = true;
        this.success = false;
        this.error = response.data.error;
        return;
      }
      this.failed = true;
      this.error = "Algo salió mal, por favor intente más tarde";
    },
    requiredRules(field) {
      return [(v) => !!v || `${field} es obligatorio`];
    },
    onFrontPhotoChange(event) {
      this.frontPhotoFile = event.target.files[0];
    },
    onBackPhotoChange(event) {
      this.backPhotoFile = event.target.files[0];
    },
    getFrontPhotoFile() {
      const base64Image = this.getFrontPhotoDataURI;
      const imageData = base64Image.split(",")[1]; // Extract the base64 data (remove the data:image/jpeg;base64 prefix)
      if (imageData == "undefined") {
        return "";
      }
      const byteCharacters = atob(imageData); // Decode the base64 data

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i); // Convert the characters to byte values
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" }); // Create a Blob object from the byte array

      const file = new File([blob], "front_photo.jpg", { type: "image/jpeg" }); // Create a File object from the Blob
      return file;
    },
    getBackPhotoFile() {
      const base64Image = this.getBackPhotoDataURI;
      const imageData = base64Image.split(",")[1]; // Extract the base64 data (remove the data:image/jpeg;base64 prefix)
      if (imageData == "undefined") {
        return "";
      }
      const byteCharacters = atob(imageData); // Decode the base64 data

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i); // Convert the characters to byte values
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" }); // Create a Blob object from the byte array

      const file = new File([blob], "back_photo.jpg", { type: "image/jpeg" }); // Create a File object from the Blob

      return file;
    },
    async deleteCurrentMember() {
      const memberId = this.$route.params.id;
      let response = await deleteMember(memberId);
      if (response) {
        if (response.data.status != "failed") {
          this.failed = false;
          this.success = true;
          this.error = "";
          const path = `/members`;
          if (this.$route.path !== path) this.$router.push(path);
          return;
        }
        this.failed = true;
        this.success = false;
        this.error = response.data.error;
        return;
      }
      this.failed = true;
      this.error = "Algo salió mal, no se pudo eliminar el usuario";
    },
  },
};
</script>
  
  <style scoped>
.no-spinner input[type="number"] {
  /* Hide the spinner controls for Vuetify input fields */
  -moz-appearance: textfield; /* Firefox */
  -webkit-appearance: none; /* Webkit-based browsers */
  appearance: none;
}
.headline {
  font-size: 24px;
  text-align: center;
  margin-bottom: 16px;
}

.memberValue {
  font-size: 25px;
}

.tittle {
  font-size: 25px;
}
</style>
  