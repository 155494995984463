<template>
    <v-container>
        <v-text-field style="width: 95%; margin-left: 1%" v-model="search" label="Buscar" placeholder="Buscar filtro"
            append-icon="mdi-magnify" clearable hide-details outlined class="elevation-1"
            @click:clear="clearSearch"></v-text-field>
        <v-data-table :headers="tableHeaders" :items="filterElements" :items-per-page="5" item-key="ID"
            :sort-by="defaultSortBy" :sort-desc="defaultSortDesc" @click:row="onRowClick">
        </v-data-table>
    </v-container>
</template>
  
<script>
export default {
    data() {
        return {
            items: [], // Replace members with items
            currentPage: 1,
            pageSize: 20,
            totalPages: 0,
            loaded: false,
            search: "",
            tableHeaders: [], // Define an empty array for table headers
            defaultSortBy: "fechaActualizacion",
            defaultSortDesc: true,
        };
    },
    async mounted() {
        setTimeout(() => {
            // Initialize the autocomplete and other Google Maps related code
            this.setupTableHeaders();
        }, 150);
    },
    props: {
        tableItems: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        filterElements() {
            let query = ""
            if (this.search) {
                query = this.search.toLowerCase();
            }

            return this.tableItems.filter((item) => {
                // Convert direccion object to a string for searching
                return (
                    Object.values(item).some((value) =>
                        value.toString().toLowerCase().includes(query)
                    )
                );
            });
        },
    },
    methods: {
        clearSearch() {
            // Update the data property when the clearable button is clicked
            this.search = "";
        },
        async setupTableHeaders() {
            // Dynamically set the table headers based on the first item in the items array
            if (this.tableItems.length > 0) {
                this.tableHeaders = Object.keys(this.tableItems[0]).map((key) => ({
                    text: key,
                    value: key,
                }));
            }
        },
        onRowClick(item) {
            // Emit an event to the parent component with the clicked row's data
            this.$emit("row-clicked", item);
        },
    },
    filters: {
        formatDate(value) {
            if (value) {
                const date = new Date(value);

                const day = date.getDate().toString().padStart(2, "0");
                const month = (date.getMonth() + 1).toString().padStart(2, "0");
                const year = date.getFullYear();
                const hours = date.getHours() % 12 || 12;
                const minutes = date.getMinutes().toString().padStart(2, "0");
                const seconds = date.getSeconds().toString().padStart(2, "0");
                const ampm = date.getHours() >= 12 ? "PM" : "AM";

                const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;

                return formattedDate;
            }
            return "";
        },
    },
};
</script>
  