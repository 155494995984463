<template>
  <v-card v-if="ready" style="margin-left: 2rem; margin-right: 2rem">
    <v-card-title class="headline">Detalles</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">ID:</strong>
          <span class="memberValue">{{ member.ID }}</span>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Cedula:</strong>
          <span class="memberValue">{{ member.cedula }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Names:</strong>
          <span class="memberValue">{{ member.nombres }}</span>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Last Names:</strong>
          <span class="memberValue">{{ member.apellidos }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Front Photo:</strong>
          <v-img
            width="600"
            height="400"
            :src="getFrontPhotoDataURI"
            alt="Front Photo"
          />
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Back Photo:</strong>
          <v-img
            width="600"
            height="400"
            :src="getBackPhotoDataURI"
            alt="Back Photo"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Fecha Nacimiento:</strong>
          <span class="memberValue">{{ member.fechaNacimiento }}</span>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Sexo:</strong>
          <span class="memberValue">{{ member.sexo }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Cargo:</strong>
          <span class="memberValue">{{ member.cargo }}</span>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Demarcacion:</strong>
          <span class="memberValue">{{ member.demarcacion }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Facebook:</strong>
          <span class="memberValue">{{ member.redesSociales.facebook }}</span>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Instagram:</strong>
          <span class="memberValue">{{ member.redesSociales.instagram }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Twitter:</strong>
          <span class="memberValue">{{ member.redesSociales.twitter }}</span>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">TikTok:</strong>
          <span class="memberValue">{{ member.redesSociales.tiktok }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Youtube:</strong>
          <span class="memberValue"> {{ member.redesSociales.youtube }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Telefono Casa:</strong>
          <span class="memberValue">{{ member.telefonoCasa }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Celular 1:</strong>
          <span class="memberValue">{{ member.celular1 }}</span>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Celular 2:</strong>
          <span class="memberValue">{{ member.celular2 }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Direccion Provincia:</strong>
          <span class="memberValue">{{ member.direccion.provincia }}</span>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Direccion Municipio:</strong>
          <span class="memberValue">{{ member.direccion.municipio }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Direccion Distrito Municipal:</strong>
          <span class="memberValue">{{
            member.direccion.distritoMunicipal
          }}</span>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Direccion Seccion:</strong>
          <span class="memberValue">{{ member.direccion.seccion }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Direccion Barrio:</strong>
          <span class="memberValue">{{ member.direccion.barrio }}</span>
        </v-col>
        <v-col cols="6">
          <strong class="tittle">Direccion Calle:</strong>
          <span class="memberValue">{{ member.direccion.calle }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <strong class="tittle">Direccion Numero:</strong>
          <span class="memberValue">{{ member.direccion.numero }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
  
  <style scoped>
.headline {
  font-size: 24px;
  text-align: center;
  margin-bottom: 16px;
}

.memberValue {
  font-size: 25px;
}

.tittle {
  font-size: 25px;
}
</style>
  
<script>
import { fetchMember } from "@/utils/apiRequest.js";
export default {
  data() {
    return {
      member: null,
      ready: false,
    };
  },
  async mounted() {
    // Fetch member data from the API
    const memberId = this.$route.params.id; // Assuming the member ID is passed as a route parameter
    let response = await fetchMember(memberId);
    if (response) {
      this.member = response.data;
      this.ready = true;
    }
  },
  computed: {
    getFrontPhotoDataURI() {
      return `data:image/jpeg;base64,${this.member.cedulaFotos.front}`;
    },
    getBackPhotoDataURI() {
      return `data:image/jpeg;base64,${this.member.cedulaFotos.back}`;
    },
  },
  methods: {},
};
</script>
  