<template>
  <v-app app>
    <v-main>
      <navigation v-if="!$route.meta.hideNavbar" />
      <router-view style="min-height: 100%; font-family: 'Roboto', sans-serif" />
      <FooterComponent v-if="!$route.meta.hideNavbar" />
    </v-main>
  </v-app>
</template>

<script>
import navigation from "@/components/navbar/navbarComponent.vue";
import FooterComponent from "@/components/footer/footerComponent.vue";
import axios from "axios";
import Cookies from "js-cookie";

axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  (config) => {
    config.headers['r-token'] = `${Cookies.get("jwt")}`;
    return config;
  },
  (error) => {
    console.log(error);
    console.log("not auth");
  }
);

export default {
  name: "App",
  components: {
    navigation,
    FooterComponent: FooterComponent,
  },
  data() {
    return {
      drawer: true,
    };
  },
};
</script>

