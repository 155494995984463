import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router/index.js'
Vue.config.productionTip = false;
Vue.config.ignoredElements = [/ion-\w*/];
import vuetify from '@/plugins/vuetify'
import VueObserveVisibility from 'vue-observe-visibility'
import { VueMaskDirective } from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.directive("mask", VueMaskDirective);
Vue.use(VueObserveVisibility)

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDBQtcJgYFrLBEYkZ_EIOBF0l2lV2xJqpM",
    libraries: "places",
  },
});

new Vue({
  el: '#app',
  vuetify,
  router,
  render: h => h(App),
})