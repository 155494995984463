<template>
  <v-container>
    <h1>Registrar miembro</h1>
    <RegistrationForm />
  </v-container>
</template>
  
<script>
import RegistrationForm from "@/components/registerMember.vue";

export default {
  components: {
    RegistrationForm,
  },
  data() {
    return {
      cedula: "",
      nombres: "",
      apellidos: "",
      fechaNacimiento: "",
      sexo: "",
      direccion: {
        provincia: "",
        municipio: "",
        distritoMunicipal: "",
        seccion: "",
        barrio: "",
        calle: "",
        numero: "",
      },
      cargo: "",
      demarcacion: "",
      cedulaFotos: {
        front: null,
        back: null,
      },
      redesSociales: "",
      telefonoCasa: "",
      celular1: "",
      celular2: "",
      sexos: ["Hombre", "Mujer", "Prefiero no decir"],
      cargos: [
        "VICESECRETARIO NACIONAL",
        "VICESECRETARIO REGIONAL",
        "VICESECRETARIO PROVINCIAL",
        "DIRECTOR PROVINCIAL",
        "ENCARGADO MUNICIPAL",
        "COORDINADOR",
        "ENLACE",
      ],
    };
  },
  methods: {
    async handleSubmit() {
      // Prepare the data object to send to the endpoint
    },
  },
};
</script>
  