<template>
  <v-container>
    <MemberList />
  </v-container>
</template>
  
  <script>
import MemberList from "@/components/memberList.vue";

export default {
  components: {
    MemberList,
  },
};
</script>
  