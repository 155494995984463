import Vue from "vue"
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"
import "@mdi/font/css/materialdesignicons.css"
import es from "vuetify/src/locale/es.ts"

Vue.use(Vuetify)

const opts = {
    breakpoint: {
        thresholds: {
            xs: 340,
            sm: 540,
            md: 600,
            lg: 1280,
        },
        scrollBarWidth: 24,
    },
    lang: {
        locales: { es },
        current: 'es',
    },
    theme: {
        variations: {
            colors: ['primary', 'secondary'],
            lighten: 1,
            darken: 2,
        },
        themes: {
            light: {
                primary: '#04a44b',
                secondary: '#009A43',
                tertiary: '#009A45',
                gray: '#009A45',
                alert: '#800404'
            },
        },
    },
}

export default new Vuetify(opts)