<template>
  <div class="map-container">
    <div ref="map" class="map"></div>
  </div>
</template>

<script>
export default {
  name: "GoogleMaps",
  props: {
    markers: Array,
    routes: Array,
    focusOnPerson: Boolean,
  },
  data() {
    return {
      map: null,
      directionsService: null,
      directionsDisplayArray: [], // Array to store DirectionsRenderer instances
      center: {
        lat: 18.474109871562145,
        lng: -69.89758783525434,
      },
    };
  },
  async mounted() {
    setTimeout(() => {
      this.locateGeoLocation();
      this.initMap();
    }, 100);
  },
  watch: {
    routes: {
      handler: "drawRoutes",
      immediate: true, // Call the handler immediately when the component is created
    },
  },
  methods: {
    async initMap() {
      this.map = new google.maps.Map(this.$refs.map, {
        center: this.center,
        zoom: 12,
      });

      this.setupDirectionsService();

      if (this.markers) {
        this.addMarkers();
      }
    },
    setupDirectionsService() {
      this.directionsService = new google.maps.DirectionsService();
    },
    addMarkers() {
      this.markers.forEach((marker) => {
        const position = { lat: marker.getPosition().lat(), lng: marker.getPosition().lng() };
        const title = marker.getTitle();
        const description = marker.description; // Access the custom description property

        this.createMarker(position, title, description);
      });
    },
    createMarker(position, title, description) {
      const infowindow = new google.maps.InfoWindow({
        content: `<div><strong>${title}</strong><br>${description}</div>`,
      });

      const marker = new google.maps.Marker({
        position,
        map: this.map,
        title,
      });

      this.map.setCenter(position);
      this.map.setZoom(15);
      
      // Add a click event listener to display the info window when the marker is clicked
      marker.addListener("click", () => {
        // Open the info window
        infowindow.open(this.map, marker);

      });
    },

    locateGeoLocation() {
      if (!this.focusOnPerson) {
        navigator.geolocation.getCurrentPosition((res) => {
          this.center = {
            lat: res.coords.latitude,
            lng: res.coords.longitude,
          };
        });
      } else {
        const pos = this.markers[0];
        if (!isNaN(pos.lat) && !isNaN(pos.lng)) {
          this.center = {
            lat: pos.lat,
            lng: pos.lng,
          };
        } else {
          navigator.geolocation.getCurrentPosition((res) => {
            this.center = {
              lat: res.coords.latitude,
              lng: res.coords.longitude,
            };
          });
        }
      }
    },
    drawRoutes() {
      if (this.routes && this.routes.length > 0) {
        // Wait for the next tick to ensure the map is fully initialized
        this.$nextTick(() => {
          // Initialize DirectionsRenderer instances for each route
          this.directionsDisplayArray = this.routes.map(() => new google.maps.DirectionsRenderer({
            suppressMarkers: true, // Disable markers
          }));

          // Attach DirectionsRenderer instances to the map
          this.directionsDisplayArray.forEach((display) => display.setMap(this.map));

          // Iterate through the routes and draw each one
          this.routes.forEach((route, index) => {
            const request = {
              origin: route.legs[0].start_location,
              destination: route.legs[0].end_location,
              waypoints: route.legs[0].steps.map((step) => ({
                location: step.start_location,
                stopover: true,
              })),
              travelMode: google.maps.TravelMode.DRIVING,
            };

            // Use the DirectionsService to get route directions
            this.directionsService.route(request, (response, status) => {
              if (status === google.maps.DirectionsStatus.OK) {
                // Display the route on the map using the corresponding DirectionsRenderer
                this.directionsDisplayArray[index].setDirections(response);
              }
            });
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.map-container {
  height: 60rem;
}

.map {
  height: 100%;
  width: 100%;
}
</style>
